export default {
  state: {
    showDialogPage: false,  //权限管理弹出框
    showDepartDialog: false,  //部门管理
    isEdit: false
  },
  mutations: {
    SET_DIALOG_SHOW(state, value) {
      state.showDialogPage = value
    },
    SET_DEPART_DIALOG(state, value) {
      state.showDepartDialog = value
    },
    SET_IS_EDIT(state, value) {
      state.isEdit = value
    }
  }
}