/**
 * @date 20230711
 * @file 供应订单-国内改期 接口函数
 */
import request from '@/utils/request'

//获取改期订单数据
export const querySupplyRescheList = (params) => {
  return request({
    url: '/SupplyChangeOrder/QuerySupplyChangeOrderList',
    method: 'get',
    params
  })
}

//获取改期订单详情数据
export const supplyRescheDetail = (data) => {
  return request({
    url: '/SupplyChangeOrder/QuerySupplyChangeOrderDetails',
    method: 'post',
    data,
    headers: {
      "Content-Type": 'application/x-www-form-urlencoded'
    }
  })
}

//操作改期订单详情数据
export const supplyRescheOperate = (data) => {
  return request({
    url: '/SupplyChangeOrder/SupplyChangeOrderOperate',
    method: 'post',
    data,
/*     headers: {
      "Content-Type": 'application/x-www-form-urlencoded'
    } */
  })
}
