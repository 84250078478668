/**
 * @date 20240805
 * @file 供应订单-酒店订单 接口函数
 */
import request from '@/utils/request';

//获取酒店订单数据
export const querySupplyHtList = (params) => {
  return request({
    url: '/Hotel/QuerySupplyHtOrderList',
    method: 'get',
    params
  })
}

//获取订单详情数据
export const querySupplyHtOrderDetails = (data) => {
  return request({
    url: '/Hotel/QuerySupplyHtOrderDetails',
    method: 'post',
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  })
}

//订单处理
export const supplyHtOrderOperate = (data) => {
  return request({
    url: '/Hotel/SupplyHtOrderOperate',
    method: 'post',
    data
  })
}