/**
 * 供应订单
 */
import Vue from "vue";
export default {
  state: {
    detailData: {},
    draftDetailShow: false,  //供应订单-国内出票-订单详情
    offlineRefundDialog: false, //供应订单 - 国内出票，退票
    offlineChangeDialog: false, //供应订单 - 国内出票，改期
    refundDetailShow: false,  //供应订单-国内退票-订单详情
    rescheDetailShow: false,  //供应订单-国内改期-订单详情
    trainDetailShow: false,  //供应订单-火车票-订单详情
    hotelDetailShow: false,  //供应订单-酒店-订单详情
    orderNo: '',
    isChangeFee: false, //供应订单-国内退票-处理订单，是否改变退票手续费
  },
  mutations: {
    setDetailData(state, data) {
      data.Passenger.forEach((v) => { 
        v.FullName = v.SurName +'-'+ v.GivenName;
        Vue.set(v, 'isPnrSelected', false)
        Vue.set(v, 'isTicketNoSelected', false)
      })
      data.Voyage.forEach((item) => { item.TravelInfo = item.Dep + '<br>' + item.Arr })
      state.detailData = data
    },
    showOfflineRefundPage(state, value) {
      state.offlineRefundDialog = value
    },
    showOfflineChangePage(state, value) {
      state.offlineChangeDialog = value
    },
    showDraftTetailPage(state, value) {
      state.draftDetailShow = value;
    },
    showRefundDetailPage(state, value) {
      state.refundDetailShow = value;
    },
    showRescheDetailPage(state, value) {
      state.rescheDetailShow = value;
    },
    showTrainDetailPage(state, value) {
      state.trainDetailShow = value;
    },
    showHotelDetailPage(state, value) {
      state.hotelDetailShow = value;
    },
    setOrderNo(state, data) {
      state.orderNo = data
    },
    changeFee(state, value) {
      state.isChangeFee = value
    }
  }
}