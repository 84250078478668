import request from '@/utils/request';

//查询供应订单-国内出票数据
export const querySupplyRefundList = (params) => {
  return request({
    url: `/SupplyRefundOrder/QuerySupplyRefundOrderList`,
    method: 'get',
    params
  })
}
//查询供应订单-国内出票详情数据
export const supplyRefundDetails = (data) => {
  return request({
    url: `/SupplyRefundOrder/QueryRefundOrderDetails`,
    method: 'post',
    data,
    headers: {
			'Content-Type': "application/x-www-form-urlencoded"
		}
  })
}

export const supplyRefundOperate = (data) => {
  return request({
    url: '/SupplyRefundOrder/SupplyRefundOrderOperate',
    method: 'post',
    data
  })
}