/**
 * 登录
 */
export default {
  state: {
    token: ''
  },
  mutations: {
    SET_TOKEN(state, value) {
      return state.token = value;
    }
  }
}