<template>
  <div class="left-nav">
    <div class="left-logo" @click="handleLogo">
      <img width="45" height="45" src="@/assets/imgs/icon.png" alt="logo" />
      <img v-show="!isCollapse" class="logo-text" src="@/assets/imgs/logo-text.png" alt="">
    </div>
    <el-menu
      class="el-menu-vertical-demo"
      router
      :unique-opened="true"
      :collapse="isCollapse"
      :default-active="curActive">
      <!-- 动态路由 -->
      <template v-for="(menu, i) in navLists">
        <el-submenu v-if="menu.children && menu.children.length !== 0" :index="menu.path" :key="menu.path + i" :hidden="menu.hidden">
          <template slot="title">
            <i :class="menu.meta.icon"></i>&nbsp;
            <span>{{menu.meta.title}}</span>
          </template>
          <el-menu-item v-for="item in menu.children" :key="item.path" :index="item.path">
            <template slot="title">
              <i :class="item.meta.icon"></i>&nbsp;
              <span>{{item.meta.title}}</span>
            </template>
          </el-menu-item>
        </el-submenu>
        <el-menu-item v-if="menu.children > 0" :key="menu.path">
          <template slot="title">
            <i :class="menu.meta.icon"></i>&nbsp;
            <span>{{menu.meta.title}}</span>
          </template>
        </el-menu-item>
      </template>
    </el-menu>
  </div>
</template>

<script>
import {staticRouter} from '@/utils/menusGlobal'
  export default {
    data() {
      return {
        isCollapse: false,  //导航是否折叠
        staticRouter: []
      }
    },
    created() {
      //解决刷新时，显示隐藏的文字
      this.isCollapse = document.body.clientWidth > 1000 ? false : true;

      //监听窗口大小变化,大于1000，显示导航文字，否则隐藏
      window.addEventListener("resize", () => {
        let w = document.body.clientWidth;
        if (w < 1000) {
          this.isCollapse = true;
        } else {
          this.isCollapse = false;
        }
      });
      //接收头部传递过来的状态
      this.$bus.$on("changeCollapse", (data) => {
        this.isCollapse = data;
      });
    },
    methods: {
      handleLogo() {
        this.$router.push({path: '/notice/plat'})
      }
    },
    computed: {
      navLists() {
        let dynamicRouter = this.$store.state.navTabs.routeArr;
        return [].concat(staticRouter, dynamicRouter)
      },
      //当前激活导航
      curActive() {
        return this.$route.path
      },
    },
  }
</script>

<style lang="less" scoped>
.left-nav {
  .left-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    cursor: pointer;
    .logo-text {
      width: 115px;
      margin-left: 5px;
    }
  }
  .el-menu {
    border-right: 0px;
    .el-menu-item {
      padding: 0;
      min-width: 0;
    }
  }
  .el-menu:not(.el-menu--collapse) {
    width: 200px;
  }
}
</style>