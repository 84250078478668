<!-- 火车票订单详情 -->
<template>
  <div class="supply-train-detial" v-show="trainDetailShow">
    <el-page-header @back="goBack" content="火车票订单详情"></el-page-header>
    <div class="content">
      <el-row>
        <el-col :span="6">订单号：{{tag.detialData.OrderNo}}</el-col>
        <el-col :span="6">订单总金额：{{tag.detialData.TotalAmount}}</el-col>
        <el-col :span="6">订单时间：{{tag.detialData.AddTime}}</el-col>
        <el-col :span="6">订单状态：{{tag.detialData.OrderStateDesc}}</el-col>
      </el-row>
      <el-divider content-position="left"><i class="el-icon-s-promotion"></i> 列车信息</el-divider>
      <el-table :data="tag.detialData.Voyage" size="mini" style="width: 100%" :header-cell-style="{background: '#eee', color: '#555'}">
        <el-table-column prop="TravelInfo" label="行程信息"></el-table-column>
        <el-table-column prop="TrainNo" label="车次"></el-table-column>
        <el-table-column prop="DepTime" label="起飞时间"></el-table-column>
        <el-table-column prop="ArrTime" label="到达时间"></el-table-column>
      </el-table>
      <el-divider content-position="left"><i class="fa fa-user-circle"></i> 乘客信息</el-divider>
      <el-table :data="tag.detialData.Passenger" size="mini" style="width: 100%" :header-cell-style="{background: '#eee', color: '#555'}">
        <el-table-column prop="Name" label="乘客姓名"></el-table-column>
        <el-table-column prop="Certificate" label="证件类型"></el-table-column>
        <el-table-column prop="CertificateNumber" label="证件号" width="150"></el-table-column>
        <el-table-column prop="Authority" label="发证国"></el-table-column>
        <el-table-column prop="Country" label="国籍"></el-table-column>
        <el-table-column prop="DateOfExpiry" label="证件有效期" width="150"></el-table-column>
        <el-table-column prop="SeatInfo" label="座位信息" width="160">
          <template slot-scope="scope">
            <el-input v-if="tag.isProcessOrder && scope.row.isTicketNoSelected" v-model="scope.row.SeatInfo"
              @blur="checkTicketNo(scope.row)" size="mini"></el-input>
            <div class="ticket-no" v-else @click="toggle(scope.row, scope.column)">{{ scope.row.SeatInfo }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="SeatType" label="座位类型" width="160"></el-table-column>
        <el-table-column prop="Position" label="座位号" width="160"></el-table-column>
      </el-table>
      <el-divider content-position="left"><i class="el-icon-s-ticket"></i> 票价信息</el-divider>
      <el-table :data="tag.detialData.PriceInfo" size="mini" style="width: 100%" :header-cell-style="{background: '#eee', color: '#555'}">
        <el-table-column prop="Type" label="类型"></el-table-column>
        <el-table-column prop="Price" label="销售价"></el-table-column>
      </el-table>
      <el-divider content-position="left"><i class="el-icon-s-finance"></i> 交易明细</el-divider>
      <el-table :data="tag.detialData.TransactionDetails" size="mini" style="width: 100%" :header-cell-style="{background: '#eee', color: '#555'}">
        <el-table-column prop="Desc" label="交易类型"></el-table-column>
        <el-table-column prop="MerchantOrderNo" label="交易号"></el-table-column>
        <el-table-column prop="State" label="交易状态"></el-table-column>
        <el-table-column prop="RealityAmount" label="交易金额"></el-table-column>
        <el-table-column prop="TransactionTime" label="交易时间"></el-table-column>
      </el-table>
      <el-divider content-position="left"><i class="el-icon-notebook-1"></i>操作日志</el-divider>
      <div class="log" v-for="(item, index) in tag.detialData.Log" :key="index">
        {{ item.OperatorTime + '&nbsp;【' + item.Operator + '】&nbsp;' + item.Info }}
      </div>
      <div class="button" v-if="tag.isProcessOrder">
        <div>
          <el-button @click="draftSuccess" size="mini" type="success">出票完成</el-button>
          <el-button @click="dialogFormVisible = true" size="mini" type="warning">拒绝出票</el-button>
        </div>
      </div>
      <!-- 拒绝出票弹出框 -->
      <el-dialog :visible="dialogFormVisible">
        <el-form :model="subRefuseForm" :rules="rules" ref="refuseForm" label-width="120px">
          <el-form-item label="请输入拒绝理由" prop="refuseValue">
            <el-input v-model="subRefuseForm.refuseValue" type="textarea"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="quitRefuse">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { supplyRwOrderOperate } from '@/api/supply/train.js'
export default {
  name: 'supply-train-detial',
  props: {
    tag: { type: Object }
  },
  data() {
    return {
      trainData: {},
      activeNames: [],
      dialogFormVisible: false,
      subRefuseForm: {
        refuseValue: '',
      },
      rules: {
        refuseValue: [{ required: true, message: '请输入拒绝理由！', trigger: 'blur' }]
      }
    }
  },
  computed: {
    trainDetailShow() {
      return this.$store.state.supply.trainDetailShow
    },
    orderTags() {
      return this.$store.state.tags.orderTags;
    }
  },
  mounted() { 
    this.trainData = { ...this.tag.detialData };
  },
  methods: {
    checkTicketNo(row) {
      supplyRwOrderOperate({ Type: "SeatInfo", PrimaryKey: row.Id, Value: row.SeatInfo }).then(res => {
        if (res.data.code == 0) {
          this.$message({ type: 'success', message: res.data.msg });
          row.isTicketNoSelected = false;
        } else {
          this.$message({ type: 'error', message: res.data.msg });
        }
      })
    },
    toggle(row) {
      row.isTicketNoSelected = true;
    },
    //出票完成
    draftSuccess() {
      let params = {
        Type: "TicketingCompleted",
        PrimaryKey: this.tag.No,
        Value: ""
      }
      let t = this.tag.detialData.Passenger.every(item => item.SeatInfo)  //座位信息不能为空
      if (!t) {
        this.tag.detialData.Passenger.forEach(item => {
          if (!item.SeatInfo) {
            item.isTicketNoSelected = true
          }
        })
        return this.$message({ type: 'warning', message: '请填写乘客座位信息!' })
      }
      supplyRwOrderOperate(params).then(res => {
        if (res.data.code == 0) {
          this.$message({ type: 'success', message: res.data.msg });
          this.goBack()
        } else {
          this.$message({ type: 'warning', message: res.data.msg });
        }
      })
    },
    //拒绝出票
    quitRefuse() {
      let params = {
        Type: "TicketingRefusal",
        PrimaryKey: this.tag.No,
        Value: this.subRefuseForm.refuseValue
      }
      this.$refs['refuseForm'].validate((valid) => {
        if (valid) {
          supplyRwOrderOperate(params).then(res => {
            if (res.data.code == 0) {
              this.$message({ type: 'success', message: res.data.msg });
              this.dialogFormVisible = false
              this.goBack()
            } else {
              this.$message.error(res.data.msg);
            }
          })
        }
      })
    },
    //返回
    goBack() {
      this.$store.commit('showTrainDetailPage', false)
      this.$router.push({ path: '/supply/train' })  //页面跳转到供应订单-火车票订单
      this.$store.commit('colseTag', this.tag)
    },
    handleChange(val) {
      this.activeNames = val
    },
  }
}
</script>

<style lang="less" scoped>
.supply-train-detial {
  display: flex;
  flex-direction: column;
  width: 98%;
  height: 97%;
  padding: 10px;
  background: #fff;
  padding: 10px;

  .el-page-header {
    height: 24px;

    /deep/.el-page-header__content {
      font-size: 14px;
    }
  }

  .content {
    flex: 1;
    overflow-y: scroll;
  }

  .el-table {
    border-top: 1px solid #EBEEF5;
  }

  /deep/.el-divider__text {
    font-size: 16px;
    font-weight: 600;
    color: royalblue;
  }

  .el-row {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #909399;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .log {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .on-input {
    width: 100%;
    height: 100%;
  }

  /deep/.el-dialog {
    width: 40%;
  }

  .ticket-no {
    height: 23px;
  }

}
</style>