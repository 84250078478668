<template>
    <div>
        <div class="img-title">
            <div class="imgtitle-title">{{ data.title }}</div>
            <div class="imgtitle-title1">{{ data.title1 }}</div>
            <div class="imgtitle-title1">{{ data.titleEnglish }}</div>
            <div style="color:coral" class="imgtitle-title1">{{ data.activetitle }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
      data: {type: Object}
    },
}
</script>

<style lang="less" scoped>
.img-title {
  margin: 100px auto;
  text-align: center;

  .imgtitle-title {
    font-size: 42px;
    font-family: SimSun;
    color: #000;
    font-weight:bold ;
  }

  .imgtitle-title1 {
    margin: 20px 0;
    margin-top: 20px;
    font-size: 18px;
    font-size: 1.8rem;
    color: #999;
    letter-spacing: 3px;
    font-family: "宋体";
  }
}
</style>