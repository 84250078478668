/**
 * 登录的ajax函数
 */

// 引入封装好的axios
import request from '@/utils/request'

// 保存登录账号密码
export function login(data) {
	return request({
		url: '/Home/ExecuteLogin',
		method: 'post',
		data,
		headers: {
			'Content-Type': "application/json"
		}
	})
}

// 验证码
export function getCode(params) {
	return request({
		url: '/Home/GetVerifyCode',
		method: 'get',
		params,
	})
}

//验证登录
export function checkLogin(data) {
	return request({
		url: '/Home/CheckLogin',
		method: 'post',
		data
	})
}

//清缓存
export function clearCache(data) {
	return request({
		url: '/Basic/ClearCache',
		method: 'post',
		data
	})
}

//忘记密码验证码
export function getForgetCode(params) {
	return request({
		url: '/Home/GetSmsVerifyCode',
		method: 'get',
		params
	})
}

//忘记密码 - 获取手机验证码
export function forgotPasswordSms(data) {
	return request({
		url: '/Home/ForgotPasswordSms',
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	})
}

//忘记密码 - 修改密码
export function forgetPassword(data) {
	return request({
		url: '/Home/ForgotPassword',
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	})
}