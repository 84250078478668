<template>
    <div class="botton-nav">
        <div class="center">
            <div class="img-flex">
                <img src="@/assets/imgs/icon.png" style="height:80px; width:80px; margin-right:20px">
            </div>
            <div>
                <div><i class="el-icon-s-custom"></i><span>服务热线：</span>{{ data.Hotwire }}</div>
                <div><i class="el-icon-phone"></i> <span>联系电话：</span>{{ data.phone }}</div>
            </div>
            <div class="icon-flex">
                <i class="el-icon-s-opportunity"></i>
                <span>备案号：</span>
                <a class="bei-an-hao" :href="data.Addressrecord" target="_blank">{{ data.Recordnumber }}</a>
            </div>
            <div class="twodimensional">
                <img style="width: 100%;height:100%" :src="data.Twodimensional">
            </div>
        </div>
    </div>
</template>
<script>
import { jsonData } from '@/api/jsonData/jsondata'
export default {
    data() {
        return {
            data: {},
        }
    },
    created() {
        jsonData().then(res => {
            this.data = JSON.parse(res.data.data).buttonjson
        })
    },
}
</script>
<style lang="less" scoped>
.botton-nav {
    padding: 80px 0;
    color: #000;
    border-top: 1px solid #999;
    .center {
        width: 60%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding: 20px 60px;
        font-size: 16px;
        line-height: 40px;
        .img-flex {
            display: flex;
            justify-items: center;
            align-items: center;
            
        }
        .bei-an-hao {
            color: rgb(251, 47, 54);
        }
    }
    .twodimensional {
        padding: 10px;
        width: 80px;
        height:80px;
        border: 1px solid rgb(251, 47, 54);
    }
    
}
</style>