/**
 * 验证函数
 */
import store from '@/store';//引入store
import { Loading } from 'element-ui';
// 验证账号
export const checkAccount = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入账号"));
  } else {
    callback();
  }
};

// 验证密码
export const validatePass = (rule, value, callback) => {
  if (!value) {
    callback(new Error("请输入密码"));
  } else if (!/^[a-zA-Z0-9]{6,18}$/.test(value)) {
    callback(new Error("6 ~ 18 位字母/数字"));
  } else {
    callback();
  }
};
//验证6位数字登录密码
export const check6Password = (rule, value, callback) => {
  if(!value) {
    callback(new Error('请输入支付密码！'))
  } else if(!/^[0-9]{6}$/.test(value)) {
    callback(new Error('请输入6位数字密码！'))
  } else {
    callback()
  }
}
//验证姓名
export const checkName = (rule, value, callback) => {
  let reg = /[\u4e00-\u9fff]/g
  if(value == '') {
    callback(new Error('请输入姓名！'))
  } else if(!reg.test(value)) {
    callback(new Error('请输入正确的中文名称！'))
  } else {
    callback()
  }
}
//验证英文姓名
export const checkEnName = (rule, value, callback) => {
  let reg = /^[a-zA-Z]*[a-zA-Z]+$/g
  if(value == '') {
    callback(new Error('值不能为空！'))
  } else if(!reg.test(value)) {
    callback(new Error('必须为英文字母！'))
  } else {
    callback()
  }
}
// 验证电话号码
export const checkTel = (rule, value, callback) => {
  const TelReg = /^(13[0-9]|14[01456789]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
  if(!value) {
    callback(new Error('请输入电话号码！'))
  } else if(!TelReg.test(value)) {
    callback(new Error('格式错误，请输入正确号码！'))
  } else {
    callback()
  }
}

// 验证身份证号码
export const checkCardNo = (rule, value, callback, birthDay, sex) => {
  let reg = /^(\d{18}|\d{17}X)$/;
  let re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{2})(\d{1})([0-9]|X)$/); 
  let num = value.toUpperCase();
  if(!value) return callback(new Error('请输入证件号码！'));
  if(!reg.test(num)) return callback(new Error('格式错误，请重新输入！'));
  
  // 检验18位身份证的校验码是否正确。
  // 校验位按照ISO 7064:1983.MOD11-2的规定生成，X可以认为是数字10。
  let valnum;
  let arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
  let arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
  let nTemp = 0;
  for (let i = 0; i < 17; i++) {
    nTemp += num.substr(i, 1) * arrInt[i];
  }
  valnum = arrCh[nTemp % 11];
  if (valnum != num.substr(17, 1)) {
    return callback(new Error('最后一位校验码与前17位不匹配'));
  }
  callback()
}

// 验证机票预订 - 添加乘机人身份证号码
export const checkPassengerCard = (rule, value, callback, data) => {
  let reg = /^(\d{18}|\d{17}X)$/;
  let num = value.toUpperCase();
  if(!value) return callback(new Error('请输入证件号码！'));
  if(!reg.test(num)) return callback(new Error('格式错误，请重新输入！')); 
  // 检验18位身份证的校验码是否正确。
  // 校验位按照ISO 7064:1983.MOD11-2的规定生成，X可以认为是数字10。
  let valnum;
  let arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
  let arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
  let nTemp = 0;
  for (let i = 0; i < 17; i++) {
    nTemp += num.substr(i, 1) * arrInt[i];
  }
  valnum = arrCh[nTemp % 11];
  if (valnum != num.substr(17, 1)) {
    return callback(new Error('最后一位校验码与前17位不匹配'));
  }
  let checkArr = data.filter(item => item.CardNo == value )
  if(checkArr.length > 1) return callback(new Error('存在相同证件号码，请检查并修改！'))
  callback()
}