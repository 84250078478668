/**
 * 日期相关操作封装
 */
/* 补零 */
export function padZero(n) {
    return n < 10 ? '0' + n : n;
}

/* 获取年月日 */
export function getYMD(separator = '-', t = Date.now()) {
    const date = new Date(typeof separator == 'number' ? separator : t)

    let y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDate()

    // return y + s + padZero(m) + s + padZero(d)
    return [y, m, d].map(function (v) {
        return padZero(v)  // [2021, 07, 08]
    }).join(typeof separator == 'number' ? '-' : separator)
}

/* 获取月日 */
export function getMD() {
    const date = new Date();
    let m = date.getMonth() + 1
    let d = date.getDate()

    return [ m, d ].map(function (v) {
        return padZero(v)
    }).join('-')
}

/* 获取时分秒 */
export function getHMS(separator = ':', t = Date.now()) {
    const date = new Date(typeof separator == 'number' ? separator : t)
    let h = date.getHours()
    let m = date.getMinutes()
    let s = date.getSeconds()
    return [h, m, s].map(function (v) {
        return padZero(v)  // [2021, 07, 08]
    }).join(typeof separator == 'number' ? ':' : separator)
}

/* 获取年月日 时分秒 */
export function getYMDHMS(s1, s2, t) {
    return getYMD(s1, t) + ' ' + getHMS(s2, t)
}

/* 获取星期 */
export function getWeek(t = Date.now()) {
    return '星期' + ['天', '一', '二', '三', '四', '五', '六'][new Date(t).getDay()]
}

/* 获取昨天 */
export function getYseterDay() {
    let yesterday = getYMD(Date.now() - 1000*60*60*24)//今天的日期
    return yesterday + ' ' + '00:00:00'
}

/* 获取上月第一天 */
export function getFirstDay() {
    const date = new Date()
    const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1)
    return getYMD(Date.parse(firstDay)) + ' ' + '00:00:00'
}

/* 获取当天23:59:59 */
export function getLastTime() {
    let day = getYMD(Date.now())
    let time = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 -1
    let times = getHMS(time)
    return day + ' ' + times;
}

//获取前30天的日期 无时间
export function getLastDay() {
    const date = new Date()
    date.setDate(date.getDate() - 29)
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    m = m < 10 ? '0' + m : m;
    d = d < 10 ? '0' + d : d;
    return y + '-' + m + '-' + d
}

//获取明天的日期
export function getTomorrow() {
    const date = new Date()
    date.setDate(date.getDate() + 1);
    return getYMD('-', date)
}

export function getMidnightTime() {
    let today = new Date();
    today.setHours(0, 0, 0, 0); // 将时间设置为凌晨0点
    return today
}

export function getTomorrowMorning() {
    const now = new Date();
    // 加一天
    now.setDate(now.getDate() + 1);
    // 时间设置在凌晨0点
    now.setHours(0, 0, 0, 0);
    return now;
}

//获取日期差
export function getDiff(d1, d2) {
    let timestamp1 = new Date(d1).getTime();  //获取时间戳
    let timestamp2 = new Date(d2).getTime();
    let timeDiff = timestamp2 - timestamp1;
    let day = timeDiff / (1000 * 60 * 60 * 24);
    return day;
}