/**
 * 菜单管理
 */
export default {
  state: {
    actionLists: [],
    showActionDialog: false,
    menuId: '',
    menuList: {},
  },
  mutations: {
    GET_ACTION_LISTS(state, arr) {
      return state.actionLists = arr
    },
    SET_ACTION_DIALOG(state, value) {
      return state.showActionDialog = value;
    },
    SET_NENU_ID(state, value) {
      return state.menuId = value
    },
    CHANGE_MENU(state, value) {
      return state.menuList = value
    }
  },
  actions: {
  }
}