//导入vueRouter
import Vue from "vue";
import VueRouter from "vue-router";
import { routes, staticRouter, whiteList } from '@/utils/menusGlobal';//引入路由数据
import local from "@/utils/local";
import store from "@/store";
import { getRouterNav } from "@/api/system/menu";
//页面组件引入
import Layout from '@/views/admin-layout/Layout.vue'

//重写路由的push，解决跳转当前导航的警告
const originPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location){
  return originPush.call(this,location).catch(err=>err)
} 

Vue.use(VueRouter);
const router = new VueRouter({
  mode:'hash', //路由模式
  base: process.env.BASE_URL,
  routes: [...routes, ...staticRouter]  //路由
})

router.beforeEach((to, from ,next) => {
  let isLogin = local.get('tk');  //获取token，以此判断是否登录
  // let isLogin = store.state.login.token;
  /**
    判断顺序：
      是否进入静态页面，是->放行，否->下一步判断
      是否有token，是->验证token是否有效，否->判断进入的是哪个页面
      有token，且验证成功->获取动态路由并放行；验证失败->移除本地token，跳转首页
      没有token，进入后台页面->跳转登录，进入登录页面->放行
  */
 if(whiteList.indexOf(to.path) > 0) {
   next()
  }else {
    if(isLogin) {
      addRoutes()
      next()
    }else {
      if(to.path == '/login') {
        next()
      } else {
        next({path: '/login'})
      }
    }
  }
})

async function getAsyncRoutes() {
  let routes = []
  let res = await getRouterNav();
  if(res.data && res.data.length > 0) {
    routes = res.data.filter(item => {
      item.name = ''
      if(item['component'] === 'Layout') {
        item.component = Layout
      } else {
        item.component = Layout
      }
      if(item['children'] && item['children'].length > 0) {
        item['children'] = item['children'].map((v) => {
          let component = () => import('@/views/' + v.component);
          return {
            path: v.path,
            component,
            meta: {
              keepAlive: false,
              ...v.meta
            }
          }
        })
      }
      return true
    })
  }
  //机票预定保留查询数据
  routes.forEach(item => {
    item.children?.forEach(v => {
      if(v.meta.title == '机票预定') {
        v.meta.keepAlive = true
      }
    })
  })
  store.dispatch('Nav', routes);  //将动态路由信息存入仓库
  return routes
}
async function addRoutes() {
  const routes = await getAsyncRoutes();
  routes.forEach((item) => {
    router.addRoute(item)
  })
}
//导入路由
export default router