<template>
    <div v-if="show" class="open">
        <el-row :gutter="20">
            <el-col v-for="(item, index) in data.model1" :key='index' :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <div class="img1">
                    <div class="img1-box">
                        <div class="title">{{ item.title }}</div>
                        <div class="span">{{ item.span1 }}</div>
                        <div class="span">{{ item.description1 }}</div>
                        <div class="span">{{ item.number1 }}</div>
                    </div>
                    <div class="img1-box">
                        <div class="title">{{ item.title2 }}</div>
                        <div class="span">{{ item.span2 }}</div>
                        <div class="span">{{ item.description2 }}</div>
                        <div class="span">{{ item.number1 }}</div>
                    </div>
                </div>
            </el-col>
        </el-row>
        <!-- 标题 -->
        <OfficialTitle :data="data.model2"></OfficialTitle>
        <div class="img2">
            <el-row>
                <el-col style="height:500px" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <img style="height:100%;width:100%" :src="data.model2.Pictureintroduction1.imgUrl" alt="">
                </el-col>
                <el-col style="height:500px" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="img2-box">
                        <div>
                            <h3>{{ data.model2.Pictureintroduction1.title }}</h3>
                            <div>{{ data.model2.Pictureintroduction1.text }}</div>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <el-col style="height:500px" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="img2-box">
                        <div>
                            <h3>{{ data.model2.Pictureintroduction2.title }}</h3>
                            <div>{{ data.model2.Pictureintroduction2.text }}</div>
                        </div>
                    </div>
                </el-col>
                <el-col style="height:500px" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <img style="height:100%;width:100%" :src="data.model2.Pictureintroduction2.imgUrl" alt="">
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import { jsonData } from '@/api/jsonData/jsondata'
import OfficialTitle from '@/components/official/OfficialTitle.vue'
export default {
    components: { OfficialTitle },
    data() {
        return {
            data: {},
            show: false
        }
    },
    created() {
        jsonData().then(res => {
            this.data = JSON.parse(res.data.data).openjson
            this.show = true
        })
    },
}
</script>

<style lang="less" scoped>
/deep/.el-col {
    margin: 0 !important;
}
/deep/.el-row {
    margin: 0 !important;
}
.open {
    background: #f0efef;
    padding: 100px 0;
    .img1 {
        width: 80%;
        margin: 40px auto;
        padding: 20px;
        background: #fff;
        box-shadow: 2px 2px 20px 2px rgb(184, 183, 183);
        .img1-box {
            margin: 20px 0;
        }
        .title {
            padding-left: 10px;
            font-size: 20px;
            color: #000;
            margin: 10px 0;
            border-bottom: 1px solid #d7d7d7;
            border-left: 3px solid coral;
        } 
        .span {
            padding-left: 10px;
            font-size: 18px;
            color: #706f6f;
            margin: 16px 0;
            border-left: 3px solid rgb(80, 130, 255);
        }
    }
    .img1:hover .title {
            color: coral;
        }
        .img1:hover .span {
            color: rgb(80, 130, 255);
        }
}
.img2 {
    width: 80%;
    margin: 0 auto;
    background: #fff;
    .img2-box {
        padding: 0 40px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        line-height: 30px;
    }
}
</style>