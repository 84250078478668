import { JSEncrypt } from "jsencrypt";  //引入插件
import CryptoJs from 'crypto-js'
import local from '@/utils/local';

/* RSA加密 */
const pubKey = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAkPT7/j4hmLJu/22doDW3ncnmXgmWaxLLZMO8J+g/jcFqHgISb81nABRxSIJeJxDN5wTuTjdErnbxs2icvW13EJ82Ok4EeQ2U7xs78WrNUTwwYiePdrKXHgmDiwBodiOFYp47eFX/lO8y2YnQe7CFUatPDuos1gjnY7Grxr5/4Ki/Hi0AZAVerCPwgjzSBlnIINFFza+Qw2BglhUdeG28mLRZFQk04S5OmoAm/tmS2k3UzqE2y8ostihpqopvgudfrqs0ufmBb07zFN5PBeqtg0EpG0Fza2wQYu+OZy9zjOXmsIQCndd0FvQlCk5zijdPBZNHCx+Ow32P98sBcaCQNwIDAQAB"; //获取公钥
const rsa = new JSEncrypt();  //创建加密对象
rsa.setPublicKey(pubKey);   //设置公钥  

//登录加密
export function encryptRsa(data) {
  const str = CryptoJs.enc.Utf8.parse(JSON.stringify(data));
  const key = CryptoJs.enc.Utf8.parse('9F5DCE36B7AAFEA5');
  const iv = CryptoJs.enc.Utf8.parse('AA91782386CB124D')
  const encryted = CryptoJs.AES.encrypt(str, key, {
    iv: iv,
    mode: CryptoJs.mode.CBC,
    padding: CryptoJs.pad.Pkcs7 
  })
  return CryptoJs.enc.Base64.stringify(encryted.ciphertext)
}
//解密
export function decryptRSA(data) { return rsa.decrypt(data)}

/* aes cbc加密  key:A823AF45D78CA4B1  iv:48B88F8022A5E3C3 */
/* const AES_KEY = 'A823AF45D78CA4B1';
const iv = '48B88F8022A5E3C3';
const key = CryptoJs.enc.Utf8.parse(AES_KEY)
const mode = {
  iv: CryptoJs.enc.Utf8.parse(iv),
  mode: CryptoJs.mode.CBC,
} */
//加密
export function encryptAes(data) {
  const str = CryptoJs.enc.Utf8.parse(JSON.stringify(data))
  const key = CryptoJs.enc.Utf8.parse('A823AF45D78CA4B1');
  const iv = CryptoJs.enc.Utf8.parse('48B88F8022A5E3C3')
  const encryted = CryptoJs.AES.encrypt(str, key, {
    iv: iv,
    mode: CryptoJs.mode.CBC,
  })
  return CryptoJs.enc.Base64.stringify(encryted.ciphertext)
}

//解密
export function decryptAes(data) {
  const key = CryptoJs.enc.Utf8.parse('A823AF45D78CA4B1');
  const iv = CryptoJs.enc.Utf8.parse('48B88F8022A5E3C3')
  const decrypted = CryptoJs.AES.encrypt(data, key, {
    iv: iv,
    mode: CryptoJs.mode.CBC,
  })
  return decrypted.toString(CryptoJs.enc.Utf8)
}