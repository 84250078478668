import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import menus from './menus'
import domestic from './domestic'
import train from './train'
import hotel from './hotel';
import login from './login'
import supply from './supply';
import tags from './tags'
import system from './system';
import navTabs from './navTab';

Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  modules: {
    menus,
    domestic,
    hotel,
    train,
    login,
    supply,
    tags,
    system,
    navTabs,
  }
})
