/**
 * 菜单信息获取函数
 */
import request from '@/utils/request';

//获取路由导航数据
export const getRouterNav = (params) => {
  return request({
    url: '/Menu/GetSysMenuList',
    method: 'get',
    params
  })
}

//获取菜单管理-菜单数据
export const getMenus = (params) => {
  return request({
    url:'/Menu/QuerySysMenuList',
    method:"get",
    params,
    headers: {
			'Content-Type': "application/x-www-form-urlencoded"
		}
  })
}

//添加/修改菜单（新增时，请求参数Id字段值为Add; 修改时，请求参数Id的值为查询返回的ID）
export const AddOrModifyMenu = (data) => {
  return request({
    url: '/Menu/OperateSysMenu',
    method: 'post',
    data,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded'
    // }
  })
}

//删除菜单
export const delMenu = (data) => {
  return request({
    url: '/Menu/DelSysMenua',
    method: 'post',
    data,
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
