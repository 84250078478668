/**
 * @date 2024/07/09
 * @file 酒店预定
 */

export default {
  state: {
    hotelFilterCond: {
      pageIndex: 1,
      pageSize: 10,
      normal: [],
      price:'',
      star: [],
    },
    hotelData: {},
    hotelDetail: {},
    isShowDetail: false,
    paymentInfo: {}
  },
  mutations: {
    SET_HOTEL_FILTER_COND(state, obj) {
      state.hotelFilterCond = {...obj}
    },
    GET_HOTEL_LIST(state, obj) {
      state.hotelData = obj
    },
    GET_HOTEL_DETAIL(state, obj) {
      state.hotelDetail = obj;
    },
    SET_IS_SHOW_DETAIL(state, val) {
      state.isShowDetail = val
    },
    SET_PAYMENT_INFO(state, obj) {
      state.paymentInfo = obj
    }
  }
}