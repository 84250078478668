<template>
  <div class="supply-reschedule-detial" v-show="rescheDetailShow">
    <el-page-header @back="goBack" content="改期订单详情"></el-page-header>
    <div class="content">
      <el-row>
        <el-col :span="6">订单号：{{tag.detialData.OrderNo}}</el-col>
        <el-col :span="6">订单总金额：{{tag.detialData.TotalAmount}}</el-col>
        <el-col :span="6">订单时间：{{tag.detialData.AddTime}}</el-col>
        <el-col :span="6">订单状态：{{tag.detialData.OrderStateDesc}}</el-col>
      </el-row>
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="行李额信息" name="1">
          <div>{{tag.detialData.BaggageTips}}</div>
        </el-collapse-item>
        <el-collapse-item title="退改签信息" name="2">
          <div>退票信息：{{tag.detialData.RefundRule}}</div>
          <div>改签信息：{{tag.detialData.ChangeRule}}</div>
        </el-collapse-item>
      </el-collapse>
      <el-divider content-position="left"><i class="el-icon-s-promotion"></i>航班信息</el-divider>
      <el-table :data="tag.detialData.Voyage" size="mini" style="width: 100%" :header-cell-style="{background: '#eee', color: '#555'}">
        <el-table-column prop="CarrierCn" label="航司" width="80"></el-table-column>
        <el-table-column prop="FlightNo" label="航班号" width="100"></el-table-column>
        <el-table-column prop="TravelInfo" label="行程信息" width="320"></el-table-column>
        <el-table-column prop="DepTime" label="起飞时间" width="140"></el-table-column>
        <el-table-column prop="ArrTime" label="到达时间" width="140"></el-table-column>
        <el-table-column prop="Cabin" label="舱位" width="140"></el-table-column>
        <el-table-column prop="PlaneType" label="机型"></el-table-column>
        <el-table-column prop="Baggage" label="手提/托运行李额(KG)" width="140"></el-table-column>
        <el-table-column prop="MainFlightNo" label="实际承运"></el-table-column>
      </el-table>
      <el-divider content-position="left"><i class="fa fa-user-circle"></i>乘客信息</el-divider>
      <el-table :data="tag.detialData.Passenger" size="mini" style="width: 100%" :header-cell-style="{background: '#eee', color: '#555'}">
        <el-table-column prop="Name" label="乘客姓名"></el-table-column>
        <el-table-column prop="PassengerType" label="乘客类型"></el-table-column>
        <el-table-column prop="Certificate" label="证件类型"></el-table-column>
        <el-table-column prop="CertificateNumber" label="证件号" width="150"></el-table-column>
        <el-table-column prop="TicketNo" label="原票号" width="160"></el-table-column>
        <el-table-column prop="TicketNoNew" label="新票号" width="160">
          <template slot-scope="scope">
            <el-input 
              v-if="scope.row.isNewTicketNoEdit && tag.isProcessOrder" 
              @blur="checkTicketNo(scope.row)" 
              size="mini" 
              v-model="scope.row.TicketNoNew">
            </el-input>
            <div class="on-input" @click="toggle(scope.row)" v-else>{{scope.row.TicketNoNew}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="PNRNew" label="新编码">
          <template slot-scope="scope">
            <el-input 
              v-if="(scope.row.isNewPnrEdit || !scope.row.PNRNew) && tag.isProcessOrder" 
              @blur="checkNewPnr(scope.row)" 
              size="mini" 
              v-model="scope.row.PNRNew">
            </el-input>
            <div class="on-input" @click="scope.row.isNewPnrEdit = true" v-else>{{scope.row.PNRNew}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="ChangeFee" label="改期费"></el-table-column>
        <el-table-column prop="RisecabinFee" label="升舱费"></el-table-column>
      </el-table>
      <el-divider content-position="left"><i class="el-icon-s-finance"></i>交易明细</el-divider>
      <el-table :data="tag.detialData.TransactionDetails" size="mini" style="width: 100%" :header-cell-style="{background: '#eee', color: '#555'}">
        <el-table-column prop="Desc" label="交易类型"></el-table-column>
        <el-table-column prop="MerchantOrderNo" label="交易号"></el-table-column>
        <el-table-column prop="State" label="交易状态"></el-table-column>
        <el-table-column prop="RealityAmount" label="交易金额"></el-table-column>
        <el-table-column prop="TransactionTime" label="交易时间"></el-table-column>
      </el-table>
      <el-divider content-position="left"><i class="el-icon-notebook-1"></i>操作日志</el-divider>
      <div class="log" v-for="(item, index) in tag.detialData.Log" :key="index">
        {{item.OperatorTime +'&nbsp;【' + item.Operator +'】&nbsp;' + item.Info}}
      </div>
      <div class="button" v-if="tag.isProcessOrder">
        <div>
          <el-button @click="rescheduleSuccess" size="mini" type="success">改期完成</el-button>
          <el-button @click="dialogFormVisible = true" size="mini" type="warning">拒绝改期</el-button>
        </div>
      </div>
      <!-- 审核不通过弹出框 -->
      <el-dialog :visible="dialogFormVisible" >
        <el-form :model="subRefuseForm" :rules="rules" ref="refuseForm" label-width="120px">
          <el-form-item label="请输入理由" prop="refuseValue">
            <el-input v-model="subRefuseForm.refuseValue" type="textarea"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="quitRefuse">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {supplyRescheOperate} from '@/api/supply/reschedul';
const PnrReg = /^[A-Z0-9]{6}$/;
const ticketNoReg = /^(\d{3}[-]\d{10})$/g;
  export default {
    name: 'supply-reschedule-detial',
    props: {
      tag: {type: Object}
    },
    data() {
      return {
        activeNames: [],
        pnrDisable: false,
        ticketNoDisable: false,
        dialogFormVisible: false, //审核不同过弹出表单
        dialogTableVisible:false, //审核通过显示表格，展示票价信息
        docs: [],
        subRefuseForm: {
          refuseValue: '',
        },
        rules:{
          refuseValue:[{required: true, message: '请输入拒绝理由！', trigger: 'blur'}]
        }
      }
    },
    computed: {
      rescheDetailShow() {
        return this.$store.state.supply.rescheDetailShow
      },
      orderTags() {
        return this.$store.state.tags.orderTags;
      },
      rescheduleNo() {
        return this.$store.state.supply.orderNo
      }
    },
    created() { 
     },
    methods: {
      //校验新票号
      checkTicketNo(row) {
        if(ticketNoReg.test(row.TicketNoNew)) {
          supplyRescheOperate({ Type: "ModifyTicketNoNew", PrimaryKey: row.Id, Value: row.TicketNoNew }).then(res => {
            if(res.data.code == 0) {
              this.$message({type: 'success', message: res.data.msg})
              row.isNewTicketNoEdit = false;
            }
          })
        }else {
          this.$message({type: 'warning', message: '票号由前3位数字 - 后10为数字以及组成！'});
        } 
      },
      //校验新编码
      checkNewPnr(row) {
        if(PnrReg.test(row.PNRNew)) {
          supplyRescheOperate({ Type: "ModifyPnrNew", PrimaryKey: row.Id, Value: row.PNRNew }).then(res => {
            if(res.data.code == 0) {
              this.$message({type: 'success', message: res.data.msg})
              row.isNewPnrEdit = false;
            }
          })
        }else {
          this.$message({type: 'warning', message: '该值由6位大写字母或数字组成！'});
        }
      },
      toggle(row) {
        row.isNewTicketNoEdit = true;
      },
      //改期完成
      rescheduleSuccess() {
        let params = {
          Type: "ChangeCompleted",
          PrimaryKey: this.rescheduleNo,
          Value: ""
        }
        let ticketNo = this.tag.detialData.Passenger.every(item => item.TicketNoNew )
        if(!ticketNo) {
          this.tag.detialData.Passenger.forEach(item => {
            if(!item.TicketNoNew) {
              item.isNewTicketNoEdit = true
            }
          })
          return this.$message({type: 'warning', message:'新票号不能为空!'})
        }
        supplyRescheOperate(params).then(res => {
          if(res.data.code == 0) {
            this.$message({type: 'success', message: res.data.msg});
            // this.dialogTableVisible = true
            this.goBack()
          } else {
            this.$message({type: 'warning', message: res.data.msg});
          }
        })
      },
      //点击确定，关闭弹出框并返回
      quitSuccess() {
        this.dialogTableVisible = false
        this.goBack()
      },
      //拒绝改期弹出框
      quitRefuse() {
        let params = {
          Type: "ChangeRefusal",
          PrimaryKey: this.rescheduleNo,
          Value: this.subRefuseForm.refuseValue
        }
        this.$refs['refuseForm'].validate((valid) => {
          if(valid) {
            supplyRescheOperate(params).then(res => {
              if(res.data.code == 0) {
                this.$message({type: 'success', message: res.data.msg});
                this.goBack()
              } else {
                this.$message({type: 'warning', message: res.data.msg});
              }
            })
          }
        })
      },
      //返回
      goBack() {
        this.$store.commit('showRescheDetailPage', false)
        this.$router.push({path: '/supply/reschedul'})  //页面跳转到国内改期
        this.$store.commit('colseTag', this.tag)
      },
      handleChange(val) {
        this.activeNames = val
      },
    }
  }
</script>

<style lang="less" scoped>
.supply-reschedule-detial {
  display: flex;
  flex-direction: column;
  width: 98%;
  height: 97%;
  padding: 10px;
  background: #fff;
  padding: 10px;
  .el-page-header {
    height: 24px;
    /deep/.el-page-header__content {
      font-size: 14px;
    }
  }
  .content {
    flex: 1;
    overflow-y: scroll;
  }
  .el-table {
    border-top: 1px solid #EBEEF5;
  }
  /deep/.el-divider__text {
    font-size: 16px;
    font-weight: 600;
    color: royalblue;
  }
  .el-row {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #909399;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .log {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .on-input {
    width: 100%;
    height: 23px;
  }
}
</style>