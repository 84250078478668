<template>
  <div class="supply-draft-detial" v-show="refundDetailShow">
    <el-page-header @back="goBack" content="退票订单详情"></el-page-header>
    <div class="content">
      <el-row>
        <el-col :span="5">订单号：{{tag.detialData.OrderNo}}</el-col>
        <el-col :span="4">政策代码：{{tag.detialData.PolicyCode}}</el-col>
        <el-col :span="5">实际退款总金额：{{tag.detialData.ActualRefundAmount}}</el-col>
        <el-col :span="5">订单时间：{{tag.detialData.AddTime}}</el-col>
        <el-col :span="4">订单状态：{{tag.detialData.OrderStateDesc}}</el-col>
      </el-row>
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="行李额信息" name="1">
          <div>{{tag.detialData.BaggageTips}}</div>
        </el-collapse-item>
        <el-collapse-item title="退改签信息" name="2">
          <div>退票信息：{{tag.detialData.RefundRule}}</div>
          <div>改签信息：{{tag.detialData.ChangeRule}}</div>
        </el-collapse-item>
      </el-collapse>
      <el-divider content-position="left"><i class="el-icon-s-promotion"></i>航班信息</el-divider>
      <el-table :data="tag.detialData.Voyage" size="mini" style="width: 100%" :header-cell-style="{background: '#eee', color: '#555'}">
        <el-table-column prop="Carrier" label="航司"></el-table-column>
        <el-table-column prop="FlightNo" label="航班号"></el-table-column>
        <el-table-column prop="TravelInfo" label="行程信息" width="320"></el-table-column>
        <el-table-column prop="DepTime" label="起飞时间"></el-table-column>
        <el-table-column prop="ArrTime" label="到达时间"></el-table-column>
        <el-table-column prop="Cabin" label="舱位"></el-table-column>
        <el-table-column prop="Baggage" label="手提/托运行李额(KG)"></el-table-column>
        <el-table-column prop="MainFlightNo" label="实际承运"></el-table-column>
      </el-table>
      <el-divider content-position="left"><i class="fa fa-user-circle"></i>乘客信息</el-divider>
      <el-popover
        placement=""
        trigger="click"
        width="500"
        :content="content"
        :visible-arrow="false">
        <img v-loading="loading" v-for="item, index in docs" :key="index" :src="item" width="500" height="500" />
        <el-button @click="getDocument" slot="reference" type="text">证明文件</el-button>
      </el-popover>
      <el-table :data="tag.detialData.Passenger" size="mini" style="width: 100%" :header-cell-style="{background: '#eee', color: '#555'}">
        <el-table-column prop="Name" label="乘机人姓名"></el-table-column>
        <el-table-column prop="PassengerType" label="乘机人类型"></el-table-column>
        <el-table-column prop="Certificate" label="证件类型"></el-table-column>
        <el-table-column prop="CertificateNumber" label="证件号" width="150"></el-table-column>
        <el-table-column prop="TicketNo" label="票号" width="160"></el-table-column>
        <el-table-column prop="Price" label="销售价"></el-table-column>
        <el-table-column prop="Face" label="票面价"></el-table-column>
        <el-table-column prop="Tax" label="机建费"></el-table-column>
        <el-table-column prop="Yq" label="燃油费"></el-table-column>
        <el-table-column prop="TicketRefundFee" label="退票手续费">
          <template slot-scope="scope">
            <el-input 
              v-if="tag.isProcessOrder && scope.row.isFeeEdit" 
              @blur="checkRefundFee(scope.row)" 
              size="mini" 
              v-model="scope.row.TicketRefundFee">
            </el-input>
            <div class="on-input" @click="scope.row.isFeeEdit = true" v-else>{{scope.row.TicketRefundFee}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="ActualRefundAmount" label="实际退款金额"></el-table-column>
      </el-table>
      <el-divider content-position="left"><i class="el-icon-s-finance"></i>交易明细</el-divider>
      <el-table :data="tag.detialData.TransactionDetails" size="mini" style="width: 100%" :header-cell-style="{background: '#eee', color: '#555'}">
        <el-table-column prop="Desc" label="交易类型"></el-table-column>
        <el-table-column prop="MerchantOrderNo" label="交易号"></el-table-column>
        <el-table-column prop="State" label="交易状态"></el-table-column>
        <el-table-column prop="RealityAmount" label="交易金额"></el-table-column>
        <el-table-column prop="TransactionTime" label="交易时间"></el-table-column>
      </el-table>
      <el-divider content-position="left"><i class="el-icon-notebook-1"></i>操作日志</el-divider>
      <div class="log" v-for="(item, index) in tag.detialData.Log" :key="index">
        {{item.OperatorTime +'&nbsp;【' + item.Operator +'】&nbsp;' + item.Info}}
      </div>
      <div class="button" v-if="tag.isProcessOrder">
        <div>
          <el-button @click="refundSuccess" size="mini" type="success">审核通过</el-button>
          <el-button @click="dialogFormVisible = true" size="mini" type="warning">审核不通过</el-button>
        </div>
      </div>
      <!-- 审核不通过弹出框 -->
      <el-dialog :visible="dialogFormVisible" :show-close="false">
        <el-form :model="subRefuseForm" :rules="rules" ref="refuseForm" label-width="120px">
          <el-form-item label="请输入理由" prop="refuseValue">
            <el-input v-model="subRefuseForm.refuseValue" type="textarea"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="quitRefuse">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 审核通过弹出框 -->
      <el-dialog title="票价信息" :visible.sync="dialogTableVisible">
        <el-table :data="tag.detialData.Passenger">
          <el-table-column prop="Price" label="销售价"></el-table-column>
          <el-table-column prop="Face" label="票面价"></el-table-column>
          <el-table-column prop="Tax" label="机建费"></el-table-column>
          <el-table-column prop="Yq" label="燃油费"></el-table-column>
          <el-table-column prop="TicketRefundFee" label="退票手续费"></el-table-column>
          <el-table-column prop="ActualRefundAmount" label="实际退款金额"></el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="quitSuccess">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { QueryRefundFiles }  from '@/api/trip/refund'
import {supplyRefundOperate, supplyRefundDetails} from '@/api/supply/refund'
  export default {
    name: 'supply-draft-detial',
    props: {
      tag: {type: Object}
    },
    data() {
      return {
        activeNames: [],
        pnrDisable: false,
        ticketNoDisable: false,
        dialogFormVisible: false, //审核不同过弹出表单
        dialogTableVisible:false, //审核通过显示表格，展示票价信息
        docs: [],
        content: '',
        loading: true,
        subRefuseForm: {
          refuseValue: '',
        },
        rules:{
          refuseValue:[{required: true, message: '请输入拒绝理由！', trigger: 'blur'}]
        }
      }
    },
    computed: {
      refundDetailShow() {
        return this.$store.state.supply.refundDetailShow
      },
      orderTags() {
        return this.$store.state.tags.orderTags;
      },
      refundNo() {
        return this.$store.state.supply.orderNo
      }
    },
    methods: {
      //获取证明文件
      getDocument() {
        QueryRefundFiles({refundOrderNo: this.tag.detialData.RefundOrderNo}).then(res => {
          if(res.data.code == 0) {
            this.docs = res.data.data.map(item => decodeURIComponent(item.img));
            this.loading = false
            if(this.docs.length == 0) {
              this.content = '暂无证明文件！'
            }
          }
        })
      },
      //检验退款手续费
      checkRefundFee(row) {
        let val = row.TicketRefundFee.toString();
        supplyRefundOperate({ Type: "ModifyTicketTicketRefundFee", PrimaryKey: row.Id, Value: val }).then(res => {
          if(res.data.code == 0) {
            row.ActualRefundAmount = row.Price + row.Tax + row.Yq - row.TicketRefundFee;
            this.tag.detialData.ActualRefundAmount = this.tag.detialData.Passenger.reduce((value, item) => value + item.ActualRefundAmount, 0)
            this.$message({type: 'success', message: res.data.msg})
            row.isFeeEdit = false;
          } else {
            this.$message({type: 'error', message: res.data.msg})
          }
        })
      },
      //出票完成
      refundSuccess() {
        let params = {
          Type: "ReviewSuccess",
          PrimaryKey: this.refundNo,
          Value: ""
        }
        supplyRefundOperate(params).then(res => {
          if(res.data.code == 0) {
            this.$message({type: 'success', message: res.data.msg});
            this.dialogTableVisible = true
          } else {
            this.$message({type: 'warning', message: res.data.msg});
          }
        })
      },
      //审核通过后，展示票价信息，点击确定，关闭弹出框并返回
      quitSuccess() {
        this.dialogTableVisible = false
        this.goBack()
      },
      //审核不通过弹出框
      quitRefuse() {
        let params = {
          Type: "ReviewFailed",
          PrimaryKey: this.refundNo,
          Value: this.subRefuseForm.refuseValue
        }
        this.$refs['refuseForm'].validate((valid) => {
          if(valid) {
            supplyRefundOperate(params).then(res => {
              if(res.data.code == 0) {
                this.$message({type: 'success', message: res.data.msg});
                this.goBack()
              } else {
                this.$message({type: 'warning', message: res.data.msg});
              }
            })
          }
        })
      },
      //返回
      goBack() {
        this.$store.commit('showRefundDetailPage', false)
        this.$router.push({path: '/supply/refund'})  //页面跳转到国内出票
        this.$store.commit('colseTag', this.tag)
      },
      handleChange(val) {
        this.activeNames = val
      },
    }
  }
</script>

<style lang="less" scoped>
.supply-draft-detial {
  display: flex;
  flex-direction: column;
  width: 98%;
  height: 97%;
  padding: 10px;
  background: #fff;
  padding: 10px;
  .el-page-header {
    height: 24px;
    /deep/.el-page-header__content {
      font-size: 14px;
    }
  }
  .content {
    flex: 1;
    overflow-y: scroll;
  }
  .el-table {
    border-top: 1px solid #EBEEF5;
  }
  /deep/.el-divider__text {
    font-size: 16px;
    font-weight: 600;
    color: royalblue;
  }
  .el-row {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #909399;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .log {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .on-input {
    width: 100%;
    height: 100%;
  }
  /deep/.el-dialog {
    width: 40%;
  }
}
</style>