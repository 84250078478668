export default {
  state: {
    routeArr: [],
  },
  mutations: {
    SET_ROUTE(state, data) {
      state.routeArr = data
    },
  },
  actions: {
    Nav({commit}, state) {
      commit('SET_ROUTE', state)
    }
  }
}