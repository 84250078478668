import Vue from 'vue';
import App from './App.vue';
import router from './router/index';  //引入导出的路由
import store from './store';//引入store
import local from "@/utils/local";
import less from 'less'; //引入less
import ElementUI from 'element-ui'; //引入ui库
import FileSaver from 'file-saver'
import VueClipboard from 'vue-clipboard2'; //复制到剪切板
import * as XLSX from 'xlsx'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/fonts/css/font-awesome.min.css'

import "@/assets/css/normalize.css" //重置样式
import '@/assets/css/common.css'; //公共样式

Vue.prototype.$bus = new Vue()
Vue.prototype.$fileSaver = FileSaver;
Vue.prototype.$xlsx = XLSX;
Vue.use(less)
Vue.use(ElementUI);
Vue.use(VueClipboard)
Vue.config.productionTip = false
new Vue({
  router,  //注册路由
  store,
 // el: '#app',
  render: h => h(App),
  created() {
    store.dispatch('Nav', router);
  }
}).$mount('#app')
