<template>
  <div class="home" v-if="show">
    <!-- 轮播图 -->
    <div class="carousel" v-if="isShowModule1">
      <el-carousel height="800px" direction="vertical" :autoplay="true"  :interval="1500">
        <el-carousel-item v-for="(item, index) in data.Carouselmap" :key="index">
          <img  style="height:100%;width:100%" :src="item.img" alt="">
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 图一展示 -->
    <div v-if="isShowModule2">
      <!-- 标题 -->
      <OfficialTitle :data="data.module1"></OfficialTitle>
      <!-- 展示图 -->
      <div style="width:80%" class="img-content">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <img class="carousel-img" style="height:100%;width:100%" :src="data.module1.Showpictures1" alt="">
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <img class="carousel-img" style="height:100%;width:100%" :src="data.module1.Showpictures2" alt="">
          </el-col>
        </el-row>
      </div>
      <!-- 按钮 -->
      <div class="img-button">
        {{ data.module1.button }}
      </div>
    </div>
    <!-- 图二展示 -->
    <div v-if="isShowModule3">
      <!-- 标题 -->
      <OfficialTitle :data="data.module2"></OfficialTitle>
      <!-- 展示图 -->
      <div class="img-content">
        <el-carousel :interval="2000" :autoplay="true" indicator-position="none" type="card" height="600px">
          <el-carousel-item v-for="(item, index) in data.module2.product" :key="index">
            <img style="height:100%;width:100%" :src="item.img" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 按钮 -->
      <div class="img-button">
        {{ data.module2.button }}
      </div>
    </div>
    <!-- 图三展示 -->
    <div v-if="isShowModule4">
      <!-- 标题 -->
      <OfficialTitle :data="data.module3"></OfficialTitle>
      <!-- 展示图 -->
      <div class="img-content">
        <el-carousel :interval="2000" :autoplay="true" height="800px" indicator-position="none" arrow="never">
          <el-carousel-item v-for="(item, index) in data.module3.tabs" :key="index">
            <el-row>
              <el-col style="height:700px" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <img style="height:100%;width:100%" :src="item.img">
              </el-col>
              <el-col style="height:700px;background-color: rgb(241, 242, 243);" :xs="24" :sm="24" :md="12" :lg="12"
                :xl="12">
                <div class="img-content3">
                  <h3 class="title"><i class="icon" :class="item.icon"></i> {{ item.Tabtitle }}</h3>
                  <div class="text"> {{ item.text }}</div>
                </div>
              </el-col>
            </el-row>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>
<script>
import { jsonData } from '@/api/jsonData/jsondata';
import OfficialTitle from '@/components/official/OfficialTitle.vue'
export default {
  components: { OfficialTitle },
  data() {
    return {
      /* 数据 */
      data: {},
      show: false,
      isShowModule1: true,
      isShowModule2: true,
      isShowModule3: true,
      isShowModule4: true,
    }
  },
  created() {
    this.get()
  },
  methods: {
    get() {
      jsonData().then(res => {
        if (res.data.code == 0) {
          this.data = JSON.parse(res.data.data).homejson
          this.show = true;
          /* 轮播显示 */
          if(this.data.Carouselmap.length ==0) {
            this.isShowModule1 = false;
          }
          /* 模块二显示 */
          if(!this.data.module1.Showpictures1 && !this.data.module1.Showpictures2) {
            this.isShowModule2 = false;
          }
          /* 模块3显示 */
          if(this.data.module2.product.length == 0) {
            this.isShowModule3 = false;
          }
          /* 模块四显示 */
          if(this.data.module3.tabs.length == 0) {
            this.isShowModule4 = false
          }
        }
      })
    },
  },

}
</script>
<style lang="less" scoped>
.carousel {
  margin: 80px 0;
}
.carousel-img:hover{
	box-shadow: 12px 7px 17px 0px #9e9e9e;
  transform: translate(-3px, -3px);
  transition: all 0.2s linear;
}

.img-content {
  width: 90%;
  margin: 0 auto;
}

.img-button {
   text-align: center;
  padding: 20px;
  font-size: 32px;
  width: 300px;
  border: 2px solid coral;
  color: coral;
  margin: 60px auto;
  border-radius: 6px;
}

.img-button:hover {
  background: coral;
  color: #fff;
} 

.img-content3 {
  text-align: center;
  padding: 40px;

  .title {
    font-size: 30px;
    font-weight: bold;
    color: #000;
    margin: 20px 0;
  }

  .text {
    font-size: 24px;
    line-height: 40px;
    color: #232222;

  }

  .icon {
    color: coral;
  }
}
.el-col {
    margin: 30px 0 !important;
}
</style>