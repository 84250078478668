<template>
  <div v-if="showHotelDetailPage" class="hotel-supply-detail f14">
    <el-page-header @back="goBack" :content="tag.title.substring(0, 4)"></el-page-header>
    <div class="orderState pane">
      <p class="f18 fw700 mb8">{{ detail.OrderStateDesc }}</p>
      <p class="mb8">订单号： <span class="orderno">{{ detail.OrderNo }}</span></p>
      <p class="mb8">订单金额： <span class="orderno f18 fw700">￥{{ detail.TotalAmount }}</span></p>
      <p>免费取消截止时间：{{ detail.FreeCancelTime }}</p>
    </div>
    <div class="hotel-info pane">
      <p class="f20 fw700 mb8"><i class="fa fa-building"></i>&nbsp;{{ detail.HotelName }}</p>
      <p><i class="el-icon-location"></i> {{ detail.HotelAddress }}</p>
    </div>
    <div class="date-info pane">
      <div class="checkin">
        <p>入住</p>
        <p class="date f16 fw700">{{ detail.CheckInDate }}</p>
        <p style="height: 16px;">{{ detail.ArrivalTimeText }}</p>
      </div>
      <div class="duration">
        - {{ detail.StayDuration }} -
      </div>
      <div class="checkout">
        <p>离店</p>
        <p class="date f16 fw700">{{ detail.CheckOutDate }}</p>
        <p style="height: 16px;"></p>
      </div>
    </div>
    <div class="room-info pane">
      <p class="mb8 f18 fw700">
        <i class="el-icon-s-home"></i> {{ detail.RoomName }} · {{ detail.RoomCount }}间
      </p>
      <p>
        <span class="room-item">
          <img src="../../assets/imgs/bed-light.png" width="17px" height="16px">&nbsp;
          {{ detail.RoomBed }}
        </span>
        <span class="room-item">
          <i class="f16" :class="detail.RoomSmoke == '禁烟' ? 'el-icon-no-smoking' : 'el-icon-smoking'"></i>&nbsp;
          {{ detail.RoomSmoke }}
        </span>
        <span class="room-item">
          <img src="../../assets/imgs/window.png" width="18px">&nbsp;
          {{ detail.RoomWindow }}
        </span>
        <span class="room-item">
          <i class="el-icon-fork-spoon"></i>&nbsp;
          {{ detail.RoomBreakfastCout }}份早餐
        </span>
      </p>
    </div>
    <div class="person-info pane">
      <div class="mb8 f18 fw700"><i class="el-icon-user-solid"></i>&nbsp;住客信息</div>
      <div class="person-name">
        住客姓名：<span class="person-value"> {{ detail.PassengerName.join('，') }}</span>
      </div>
      <div class="tell">
        联系电话：<span class="person-value">{{ detail.ContactsPhone }}</span>
      </div>
    </div>
    <div class="transation-info pane">
      <div class="title f18 fw700"><i class="fa fa-cny"></i>&nbsp;交易记录</div>
      <el-table :data="detail.TransactionDetails">
        <el-table-column prop="Desc" label="交易类型"></el-table-column>
        <el-table-column prop="MerchantOrderNo" label="交易号"></el-table-column>
        <el-table-column prop="RealityAmount" label="交易金额"></el-table-column>
        <el-table-column prop="TransactionTime" label="交易日期"></el-table-column>
        <el-table-column prop="State" label="交易状态"></el-table-column>
      </el-table>
    </div>
    <div class="log-info pane">
      <div class="title f18 fw700"><i class="el-icon-notebook-1"></i>&nbsp;操作日志</div>
      <p class="log-item" v-for="(item, index) in detail.Log" :key="index">
        {{ item.OperatorTime + '&nbsp;【' + item.Operator + '】&nbsp;' + item.Info }}
      </p>
    </div>
    <div class="opreate-btn" v-if="tag.isProcessOrder">
      <el-button type="success" size="mini" @click="agreeDraft">出票完成</el-button>
      <el-button type="danger" size="mini" @click="refusalDraft">拒绝出票</el-button>
    </div>
    <el-dialog  :visible.sync="refusalDialogVisible" width="30%">
      <label class="mb8" style="display: block;">请输入拒绝理由</label>
      <el-input v-model="value" type="textarea" :class="{fail: !isInput}"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitRefusal">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { supplyHtOrderOperate } from '@/api/supply/hotel.js'
  export default {
    props: {
      tag: Object
    },
    data() {
      return {
        detail: null,
        refusalDialogVisible: false,
        value: '',
        isInput: false,
      }
    },
    computed: {
      showHotelDetailPage() {
        return this.$store.state.supply.hotelDetailShow;
      },
    },
    created() {
      this.detail = {...this.tag.detialData}
    },
    methods: {
      agreeDraft() {
        let params = {
          Type: 'TicketingCompleted',
          PrimaryKey: this.tag.No,
          Value: ''
        }
        supplyHtOrderOperate(params).then(res => {
          if (res.data.code == 0) {
            this.$message({ type: 'success', message: res.data.msg });
            this.goBack()
          } else {
            this.$message({ type: 'warning', message: res.data.msg });
          }
        })
      },
      refusalDraft() {
        this.value = '';
        this.refusalDialogVisible = true
      },
      submitRefusal() {
        if(!this.value) {
          this.isInput = false
          return
        }
        this.isInput = true
        let params = {
          Type: 'TicketingRefusal',
          PrimaryKey: this.tag.No,
          Value: this.value
        }
        supplyHtOrderOperate(params).then(res => {
          if (res.data.code == 0) {
              this.$message({ type: 'success', message: res.data.msg });
              this.refusalDialogVisible = false
              this.goBack()
            } else {
              this.$message.error(res.data.msg);
            }
        })
      },
      goBack() {
        this.$store.commit('showHotelDetailPage', false)
        this.$router.push({ path: '/supply/hotel' })  //页面跳转到供应订单-酒店订单
        this.$store.commit('colseTag', this.tag)
      }
    }
  }
</script>

<style lang="less" scoped>
.hotel-supply-detail {
  margin: 0 auto;
  width: 1000px;
  .el-page-header {
    padding: 10px 14px;
    background-color: #fff;
  }
  .pane {
    margin-top: 10px;
    padding: 16px;
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 5px;
    box-sizing: border-box;
  }
  .mb8 { margin-bottom: 8px; }
  .orderno {
    color: #409eff;
  }
  .date-info {
    display: flex;
    align-items: center;
    .checkin {
      margin-right: 60px;
      text-align: left;
    }
    .checkout {
      margin-left: 60px;
      text-align: right;
    }
    .date {
      margin: 10px 0;
    }
  }
  .room-info {
    .room-item {
      margin-right: 30px;
    }
    img {
      vertical-align: bottom;
    }
  }
  .person-info {
    .person-name {
      margin-bottom: 10px;
    }
    .person-value {
      margin-left: 18px;
    }
  }
  .log-item {
    margin-top: 10px;
  }
  .opreate-btn {
    margin-top: 10px;
    text-align: center;
  }
  /deep/.fail {
    .el-textarea__inner {
      border-color: #f56c6c;
    }
  }
}
</style>