import request from '@/utils/request';
//官网数据提交
export const jsonData = () => {
    return request({
      url: `HomeShow/Get`,
      method: 'post',
    })
  }
  //上传图片
export const importimg = (data) => {
  return request({
    url: '/HomeShow/UploadHomeImg',
    method: 'post',
    data,
    headers: {
      'Content-Type': "multipart/form-data"
    }
  })
}
//修改官网表单
  export function editjsonData(data) {
    return request({
      url: '/HomeShow/ModifyHome',
      method: 'post',
      data,
      headers: {
        'Content-Type': "application/x-www-form-urlencoded"
      }
    })
  }