import request from '@/utils/request';

/* 获取退票订单信息 */
export const queryRefundOrder = (params) => {
  return request({
    url: '/RefundOrder/QueryRefundOrderList',
    method: 'get',
    params
  })
}
/* 获取退票订单详情信息 */
export const queryRefundDetail = (data) => {
  return request({
    url: '/RefundOrder/QueryRefundOrderDetails',
    method: 'post',
    data,
    headers: {
			'Content-Type': "application/x-www-form-urlencoded"
		}
  })
}
/* 获取退票订单详情信息 - 证明文件 */
export const QueryRefundFiles = (data) => {
  return request({
    url: '/RefundOrder/QueryRefundOrderFiles',
    method: 'post',
    data,
    headers: {
			'Content-Type': "application/x-www-form-urlencoded"
		}
  })
}