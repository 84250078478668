/**
 * 修改密码
 * @date 2023/09/06
 */
import request from '@/utils/request'

//修改密码
export function modifyPassword(data) {
	return request({
		url: '/Basic/ModifyPersonalInfo',
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	})
}
//注销登录
export function logout(data) {
	return request({
		url: '/Basic/Logout',
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	})
}

/* 支付密码重置 start */
//发送短信验证码
export function sendSmsCode(data) {
	return request({
		url: '/Basic/ForgotPayPasswordSms',
		method: 'post',
		data
	})
}

//修改支付密码
export function modifyPaymentPass(data) {
	return request({
		url: '/Basic/ForgotPayPassword',
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	})
}