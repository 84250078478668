/**
 * tag标签
 */
import router from "@/router";
export default {
  state: {
    affixTag: [{path: '/notice/plat', title: '平台公告', affix: true}],
    routerTags: [{path: '/notice/plat', title: '平台公告', affix: true}],
    orderTags: [],
    flightTags: []
  },
  mutations: {
    addRouterTag(state, data) {
      if (state.routerTags.some(v => v.path === data.path)) return
      state.routerTags.push(
        Object.assign({}, data, {
          title: data.meta.title,
          affix: false
        })
      )
    },
    //关闭当前标签
    colseTag(state, data) {
      if(data.path) {
        state.routerTags.splice(state.routerTags.indexOf(data), 1);  //从标签列表中删除该标签
        //路由跳转到最后一个标签
        let index = state.routerTags.length - 1;  //获取标签列表最后一项
        let curPath = state.routerTags[index]
        router.push(curPath.path);  //路由跳转到当前标签
      } else {
        state.orderTags.splice(state.orderTags.indexOf(data), 1);
        if(state.orderTags.length == 0) {
          //路由跳转到最后一个标签
          let index = state.routerTags.length - 1;  //获取标签列表最后一项
          let curPath = state.routerTags[index]
          router.push(curPath.path);  //路由跳转到当前标签
        } else {
          let index = state.orderTags.length -1;
          state.orderTags.forEach((item) => item.isCurrent = false)
          state.orderTags[index].isCurrent = true
        }

      }
    },
    //关闭其他标签
    colseOtherTag(state, tag) {
      if(tag.path) {
        if(tag.path == '/notice/plat') return state.routerTags = [...state.affixTag]
        state.routerTags = state.routerTags.filter(item => item.path === tag.path )
        state.routerTags = [...state.affixTag, ...state.routerTags];
        state.orderTags = []
      } else {
        state.orderTags = state.orderTags.filter(item => {
          item.isCurrent = true
          return item.No == tag.No
        })
        state.routerTags = state.affixTag
      }
      
    },
    //关闭所有标签
    closeAllTag(state) {
      router.push({path: '/notice/plat'})
      state.orderTags = []
      state.routerTags = [];
      state.routerTags = [...state.affixTag];
    },
    addOrderTag(state, data) {
      if(state.orderTags.some(v => v.id == data.id)) {
        return
      } else {
        state.orderTags.forEach(item => item.isCurrent = false)
        state.orderTags.push(
          Object.assign({}, data)
        )
      }
    },
    //改变当前订单tag状态
    changeOrderTag(state, data) {
      if(data) {
        state.orderTags.forEach((item) => {
          item.isCurrent = false;
          if(item.id == data.id ) {
            item.isCurrent = true
          }
        })
      } else {
        state.orderTags.forEach(item => item.isCurrent = false )
      }
    }
  }
}