<template>
    <div v-if="show">
        <!-- 首图 -->
        <div class="carousel">
            <div style="height:800px">
                <img style="height:100%;width:100%" :src="data.headerimg" alt="">
            </div> 
        </div>
        <!-- 关于我们内容 -->
        <div class="bg">
            <div class="bg-img">
                <div class="title"><i class="el-icon-sunny"></i>{{ data.model1.title }}</div>
                <div class="introduce">{{ data.model1.introduce }}</div>
                <div class="imgurl"><img style="height:100%;width:100%" :src="data.model1.imgurl" alt=""></div>
                <div class="title"><i class="el-icon-sunny"></i>{{ data.model2.title }}</div>
                <div class="introduce">{{ data.model2.introduce }}</div>
                <div class="imgurl"><img style="height:100%;width:100%" :src="data.model2.imgurl" alt=""></div>
            </div>
        </div>
    </div>
</template>
<script>
import { jsonData } from '@/api/jsonData/jsondata'
export default {
    data() {
        return {
            data: {},
            show: false
        }
    },
    created() {
        jsonData().then(res => {
            this.data = JSON.parse(res.data.data).ourjson
            this.show = true
        })
    },
}
</script>

<style lang="less" scoped>
.carousel {
    margin: 80px 0;
}
.bg {
    padding: 80px 0;
    background: #f0efef;
}
.bg-img {
    width: 70%;
    margin: 0 auto;
    background: #fff;
    border: 1px solid #dbdbdb;
    padding: 40px;

    .title {
        font-size: 20px;
        color: #000;
        border-bottom: 1px solid #bdbcbc;
        padding: 20px 0;
    }

    .introduce {
        font-size: 16px;
        color: #000;
        line-height: 30px;
        margin: 30px 0;
    }

    .imgurl {
        width: 70%;
        height: 500px;
        margin: 0 auto;
    }
}
</style>