/**
 * 信息录入
 * 2023-09-19
 */
import request from '../utils/request'

export function saveInfo(data) {
	return request({
		url: '/MeetingVisitors/SaveInfo',
		method: 'post',
		data,
		headers: {
			'Content-Type': "application/json",
      'Authorization': ''
		}
	})
}
