<template>
  <div class="entry-info">
    <div class="main" v-if="isSuccess">
      <div class="title">信息录入</div>
      <el-form :model="infoForm" :rules="rules" size="mini" ref="infoForm">
        <el-form-item prop="name">
          <el-input v-model="infoForm.name" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item  prop="identifier">
          <el-input v-model="infoForm.identifier" placeholder="证件号码"></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input v-model="infoForm.phone" placeholder="电话号码"></el-input>
        </el-form-item>
        <el-form-item  prop="companyName">
          <el-input v-model="infoForm.companyName" placeholder="公司名称"></el-input>
        </el-form-item>
        <el-form-item class="btn">
          <el-button size="mini" type="primary" @click="submitForm('infoForm')">提交</el-button>
          <el-button size="mini" @click="resetForm('infoForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-else class="success">信息录入成功！</div>
  </div>
</template>

<script>
import { saveInfo } from '../../api/info';
  export default {
    data() {
      const validIndentify = (rule, value, callback) => {
        let reg = /^(\d{18}|\d{17}X)$/;
        if (!value) {
          callback(new Error('证件号码不能为空'));
        } else if (!reg.test(value)) {
          callback(new Error('证件号码必须18位!'));
        } else {
          callback();
        }
      };
      const validPhone = (rule, value, callback) => {
        if(!value) {
          callback(new Error('手机号不能为空！'))
        } else if(!/^\d{11}$/.test(value)) {
          callback(new Error('手机号必须11位！'))
        } else {
          callback()
        }
      }
      return {
        isSuccess: true,
        infoForm: {
          name: '',
          identifier: '',
          phone: '',
          companyName: ''
        },
        rules: {
          name: [{ required: true, message: '姓名不能为空！', trigger: 'blur' }],
          identifier: [{ validator: validIndentify, trigger: 'blur' }],
          phone: [{ validator: validPhone, trigger: 'blur' }],
          companyName: [{ required: true, message: '公司不能为空！', trigger: 'blur' }]
        }
      };
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            saveInfo(this.infoForm).then(res => {
              let { code, msg, data } = res.data
              if(code == 0) {
                this.isSuccess = false
                this.infoForm = {
                  name: '',
                  identifier: '',
                  phone: '',
                  companyName: ''
                }
              } else {
                this.isSuccess = true
                this.$message({type: 'error', message: msg})
              }
             })
          }
        });
      },
      resetForm(formName) {
        this.infoForm = {
          name: '',
          identifier: '',
          phone: '',
          companyName: ''
        }
        this.$refs[formName].resetFields();
      },
      bodyScroll(event) {
        event.preventDefault()
      },
      stopScroll () {
        document.body.style.overflow = 'hidden'
        document.addEventListener('touchmove', this.bodyScroll, false)
      },

      startScroll () {
        document.body.style.overflow = ''// 出现滚动条
        document.removeEventListener('touchmove', this.bodyScroll, false)
      }
    }
  }
</script>

<style lang="less" scoped>
.entry-info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: url('../../assets/imgs/bg.jpeg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .main {
    padding: 15px;
    width: 46%;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px #555;
    .title {
      width: 100%;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      line-height: 40px;
      color: #555;
    }
    .btn {
      text-align: center;
      margin-bottom: 0px;
    }
  }
  .success {
    font-size: 20px;
    font-weight: bold;
    color: #0CB618;
  }
}
</style>