<template>
    <div v-if="show">
        <!-- 首图 -->
        <div class="carousel">
            <div style="height:800px">
                <img style="height:100%;width:100%" :src="data.headerimg" alt="">
            </div>
        </div>
        <!-- 标题 -->
        <OfficialTitle :data="data.module1"></OfficialTitle>
        <!-- 图一内容 -->
        <div>
            <el-row>
                <el-col class="img1" style="height:700px" v-for="(item, index) in data.module1.imgs.imgbottons" :key="index"
                    :xs="24" :sm="12" :md="12" :lg="6" :xl="6">
                    <img style="height:100%;width:100%" :src="item.img" alt="">
                    <div class="box2">
                        <div class="img">
                           图{{ index+1 }}
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 标题 -->
        <OfficialTitle :data="data.module2"></OfficialTitle>
        <!-- 图二内容 -->
        <div class="img2-bg">
            <el-row :gutter="20">
                <el-col style="height:400px" v-for="(item, index) in data.module2.travel" :key="index" :xs="24" :sm="12"
                    :md="8" :lg="6" :xl="4">
                    <div class="img2-bgcontent">
                        <div class="img">
                            <img style="height:100%;width:100%" :src="item.img" alt="">
                        </div>
                        <div class="content">
                            <div class="title"> {{ item.subheading }} </div>
                            <div class="Content">{{ item.Content }}</div>
                            <el-tag  type="warning">{{ item.introduction1 }}</el-tag>
                            <el-tag  type="warning">{{ item.introduction2 }}</el-tag>
                        </div>
                        <div class="introduction3">{{ item.introduction3 }}</div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 标题 -->
        <OfficialTitle :data="data.module3"></OfficialTitle>
        <div class="img3-bg">
            <el-row :gutter="100">
                <el-col style="height:700px" :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <img class="img3-bgbox" style="height:100%;width:100%" :src="data.module3.img" alt="">
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                    <div class="text">{{ data.module3.text }}</div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import { jsonData } from '@/api/jsonData/jsondata'
import OfficialTitle from '@/components/official/OfficialTitle.vue'
export default {
    components: { OfficialTitle },
    data() {
        return {
            data: {},
            show: false,
        }
    },
    created() {
        jsonData().then(res => {
            this.data = JSON.parse(res.data.data).traveljson
            this.show = true
        })
    },
    //method 手风琴面板
    methods: {
    },

}
</script>

<style lang="less" scoped>
.carousel {
    margin: 80px 0;
}
.img1 {
    position: relative;

    .box2 {
        position: absolute;
        left: 0;
        top: 0;
        display: none;
        width: 100%;
        height: 100%;
        .img {
            width: 100px;
            height: 100px;
            border: 2px solid #fff;
            text-align: center;
            margin: 0 auto;
            line-height: 100px;
        }
    }
    
}

.img1:hover .box2 {
    display: block;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 30px;
    display: flex;
    justify-items: center;
    align-items: center;
}

.img2-bg {
    background: #f0efef;
    padding: 100px;

    .img2-bgcontent {
        padding: 10px;
        height: 100%;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .img {
            height: 50%;
            position: relative;
            width: 100%;

            img {
                display: block;
                height: 100%;
                margin: 0 auto;
                -webkit-transition-duration: .5s;
                -moz-transition-duration: .5s;
                transition-duration: .5s
            }
        }

        .img:hover img {
            transform: scale(1.1)
        }

        .content {
            height: 30%;

            .el-tag {
                margin: 4px;
            }

            .title {
                border-left: 3px solid coral;
                margin: 10px 0;
                padding-left: 10px;
                color: #000;
                font-size: 18px;
            }

            .Content {
                font-size: 16px;
                line-height: 20px;
                color: #373737;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
        .introduction3 {
                font-size: 20px;
                color: red;
                text-align: end;
              /*   margin: 10px 0; */
            }
    }
}

.img3-bg {
    width: 60%;
    margin: 0 auto;

    .img3-bgbox:hover {
        box-shadow: 12px 7px 17px 0px #9e9e9e;
        transform: translate(-3px, -3px);
        transition: all 0.2s linear;
    }

    .text {
        color: #000;
        font-size: 18px;
        line-height: 40px;
    }
}
.el-col {
    margin: 30px 0 !important;
}
</style>