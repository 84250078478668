<template>
    <div class="layout">
        <!-- 头部导航 -->
        <header-nav></header-nav>

        <!-- 内容出口 -->
        <div class="out">
            <router-view></router-view>
        </div>
        <!-- 底部 -->
            <button-nav class="button-nav"></button-nav>
    </div>
</template>

<script>
//引入组件
import HeaderNav from './HeaderNav.vue'
import ButtonNav from './ButtonNav.vue';
    export default {
  components: { HeaderNav,ButtonNav },
//注册
comments: {
HeaderNav,
ButtonNav
},
    };
</script>
<style lang="less" scoped>
.layout {
    height: 100%;
    display: flex;
    flex-direction: column;
    .out {
        flex: 1;
    }
}
</style>