/**
 * 国内机票
 */
import { getFirstDay, getLastTime } from '@/utils/date';
export default {
  state: {
    //搜索框
    searchFormData: {
      dateLimit: [getFirstDay(), getLastTime()],
      orderNo: '',
      ticketNo: '',
      passengerName: ''
    },
    isChange: false,  //搜索框的值是否改变
    isPayment: false, //是否支付，用于采购订单页的支付，当支付完成，重新获取表格数据
    singleAddPassenger: [], //单个添加乘机人信息
    batchAddPassenger: [],  //批量添加乘机人数据
    cityData: [], //城市数据,
    flightData: [], //机票数据
    filterFlightData: [], //筛选后的机票数据
    operateFormVisible: false,  //常用旅客添加表单显示/隐藏
    addTravellerForm: {}, //常用旅客 - 添加旅客表单
    addSuccess: false,  //旅客添加成功
    presetTicketDrawer: false,  //机票预订 - 预定机票弹出框
    presetPageRefresh: false, //刷新当前页，重新获取数据
    orderInfo: {},  //提交订单-订单信息
    applyRefundDialog: false, //采购订单 - 申请退票
    showRefundOrderDetails: false,  //退票订单 - 显示详情
    showChangeOrderFlight: false, //变更订单 - 显示新查询航班详情
  },
  mutations: {
    GET_SEARCH_DATA(state, obj) {
      return state.searchFormData = {...obj}
    },
    IS_FLIGHT_CHANGE(state, val) {
      return state.isChange = val
    },
    IS_FLIGHT_PAYMENT(state, val) {
      state.isPayment = val
    },
    SET_FLIGHT_DATA(state, arr) {
      state.flightData = arr
    },
    SET_FILTER_FLIGHT_DATA(state, arr) {
      state.filterFlightData = arr
    },
    SET_OPERATE_FORM_VISIBLE(state, val) {
      state.operateFormVisible = val
    },
    SET_ADD_TRAVELLER_FORM(state, obj) {
      state.addTravellerForm = obj
    },
    SET_ADD_SUCCESS(state, val) {
      state.addSuccess = val
    },
    //机票预订 - 单个添加乘机人信息
    ADD_FLIGHT_PASSENGERS(state, obj) {
      let type = Object.prototype.toString.call(obj).slice(8, -1)
      if(type == 'Object') {
        state.singleAddPassenger.push({...obj})
      } else {
        state.singleAddPassenger = [...obj]
      }
    },
    //机票预订 - 批量操作乘机人信息
    OPERATE_MUL_PASSENGER(state, arr) {
      let type = Object.prototype.toString.call(arr).slice(8, -1)
      if(type === "Array") {  //添加
        state.batchAddPassenger = [...arr]
      } else {  //删除
        state.batchAddPassenger = state.batchAddPassenger.filter(item => item.Id != arr)
      }
    },
    //机票预订 - 删除乘机人信息
    SET_DEL_PASSENGER(state, obj) {
      state.singleAddPassenger = state.singleAddPassenger.filter(item => item.id != obj.id)
    },
    //机票预订 - 初始化乘机人列表数据 
    INIT_FLIGHT_PASSENGERS(state) {
      state.batchAddPassenger = []
      state.singleAddPassenger = [{
        id: new Date().getTime(),
        Type: 0,
        Name: '',
        CardType: 1,
        CardNo: '',
        Gender: null,
        Birthday: '',
        Tel: '',
        Email: '',
        Country: '',
        Authority: '',
        SurName: '',
        GivenName: '',
        DateOfExpiry: ''
      }]
    },
    //机票预订弹出框显示/隐藏
    SET_FLIGHT_DRAWER(state, val) {
      state.presetTicketDrawer = val
    },
    SET_REFRESH(state, value) {
      state.presetPageRefresh = value
    },
    SET_ORDER_INFO(state, obj) {
      state.orderInfo = obj
    },
    SET_APPLY_REFUND_DIALOG(state, val) {
      state.applyRefundDialog = val
    },
    SHOW_REFUND_ORDER_DETAIL(state, val) {
      state.showRefundOrderDetails = val
    },
    SHOW_CHANGE_ORDER_FLIGHT(state, val) {
      state.showChangeOrderFlight = val
    },
  },
  getters: {
    flightPassengers(state) {
      let allArr = []
      let singleArr = state.singleAddPassenger;
      let batchArr = state.batchAddPassenger;
      batchArr.forEach(item => allArr.push(item))
      singleArr.forEach(item => allArr.push(item))
      return allArr
    }
  }
}