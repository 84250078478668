/**
 * @date 20240703
 * @file 供应订单-火车票 接口函数
 */
import request from '@/utils/request';

//获取火车票订单数据
export const querySupplyRwList = (params) => {
  return request({
    url: '/Railway/QuerySupplyRwOrderList',
    method: 'get',
    params
  })
}

//获取订单详情数据
export const querySupplyRwOrderDetails = (data) => {
  return request({
    url: '/Railway/QuerySupplyRwOrderDetails',
    method: 'post',
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  })
}

//订单处理
export const supplyRwOrderOperate = (data) => {
  return request({
    url: '/Railway/SupplyRwOrderOperate',
    method: 'post',
    data
  })
}