<template>
  <div class="supply-draft-detial" v-show="draftDetailShow">
    <el-page-header @back="goBack" content="出票订单详情"></el-page-header>
    <div class="content">
      <el-row>
        <el-col :span="5">订单号：{{ tag.detialData.OrderNo }}</el-col>
        <el-col :span="4">政策代码：{{ tag.detialData.PolicyCode }}</el-col>
        <el-col :span="5">订单总金额：{{ tag.detialData.TotalAmount }}</el-col>
        <el-col :span="5">订单时间：{{ tag.detialData.AddTime }}</el-col>
        <el-col :span="4">订单状态：{{ tag.detialData.OrderStateDesc }}</el-col>
      </el-row>
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="行李额信息" name="1">
          <div>{{ tag.detialData.BaggageTips }}</div>
        </el-collapse-item>
        <el-collapse-item title="退改签信息" name="2">
          <div>退票信息：{{ tag.detialData.RefundRule }}</div>
          <div>改签信息：{{ tag.detialData.ChangeRule }}</div>
        </el-collapse-item>
      </el-collapse>
      <el-divider content-position="left"><i class="el-icon-s-promotion"></i>航班信息</el-divider>
      <el-table :data="tag.detialData.Voyage" size="mini" style="width: 100%"
        :header-cell-style="{ background: '#eee', color: '#555' }">
        <el-table-column prop="Carrier" label="航司" width="80"></el-table-column>
        <el-table-column prop="FlightNo" label="航班号" width="100"></el-table-column>
        <el-table-column prop="TravelInfo" label="行程信息" width="320"></el-table-column>
        <el-table-column prop="DepTime" label="起飞时间" width="140"></el-table-column>
        <el-table-column prop="ArrTime" label="到达时间" width="140"></el-table-column>
        <el-table-column prop="Cabin" label="舱位" width="140"></el-table-column>
        <el-table-column prop="PlaneType" label="机型"></el-table-column>
        <el-table-column prop="Baggage" label="手提/托运行李额(KG)" width="140"></el-table-column>
        <el-table-column prop="MainFlightNo" label="实际承运"></el-table-column>
      </el-table>
      <el-divider content-position="left"><i class="fa fa-user-circle"></i>乘客信息</el-divider>
      <el-table :data="DraftData.Passenger" size="mini" style="width: 100%"
        :header-cell-style="{ background: '#eee', color: '#555' }">
        <el-table-column prop="Name" label="乘机人姓名"></el-table-column>
        <el-table-column prop="PassengerType" label="乘机人类型"></el-table-column>
        <el-table-column prop="Certificate" label="证件类型"></el-table-column>
        <el-table-column prop="CertificateNumber" label="证件号" width="150"></el-table-column>
        <el-table-column prop="FullName" label="姓-名"></el-table-column>
        <el-table-column prop="Authority" label="发证国"></el-table-column>
        <el-table-column prop="Country" label="国籍"></el-table-column>
        <el-table-column prop="DateOfExpiry" label="证件有效期" width="150"></el-table-column>
        <el-table-column prop="PNR" label="编码">
          <template slot-scope="scope">
            <el-input v-if="scope.row.isPnrSelected && tag.isProcessOrder" @blur="checkPnr(scope.row)" size="mini"
              v-model="scope.row.PNR">
            </el-input>
            <div class="on-input" @click="toggle(scope.row, scope.column)" v-else>{{ scope.row.PNR }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="TicketNo" label="票号" width="160">
          <template slot-scope="scope">
            <el-input v-if="tag.isProcessOrder && scope.row.isTicketNoSelected" v-model="scope.row.TicketNo"
              @blur="checkTicketNo(scope.row)" size="mini"></el-input>
            <div class="ticket-no" v-else @click="toggle(scope.row, scope.column)">{{ scope.row.TicketNo }}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-divider content-position="left"><i class="el-icon-s-ticket"></i>票价信息</el-divider>
      <el-table :data="tag.detialData.PriceInfo" size="mini" style="width: 100%"
        :header-cell-style="{ background: '#eee', color: '#555' }">
        <el-table-column prop="Type" label="类型"></el-table-column>
        <el-table-column prop="Price" label="销售价"></el-table-column>
        <el-table-column prop="Face" label="票面价"></el-table-column>
        <el-table-column prop="Tax" label="机建费"></el-table-column>
        <el-table-column prop="Yq" label="燃油费"></el-table-column>
      </el-table>
      <el-divider content-position="left"><i class="el-icon-s-shop"></i>附加产品信息</el-divider>
      <el-table :data="tag.detialData.Product" size="mini" style="width: 100%"
        :header-cell-style="{ background: '#eee', color: '#555' }">
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-table :data="scope.row.PolicyInfo" style="width: 100%">
              <el-table-column prop="name" label="乘机人姓名"></el-table-column>
              <el-table-column label="保险单下载地址">
                <template slot-scope="scope">
                  <el-link :href="scope.row.url" type="primary" target="_blank">下载</el-link>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column prop="ProductType" label="产品名"></el-table-column>
        <el-table-column prop="Price" label="产品单价"></el-table-column>
      </el-table>
      <el-divider content-position="left"><i class="el-icon-s-finance"></i>交易明细</el-divider>
      <el-table :data="tag.detialData.TransactionDetails" size="mini" style="width: 100%"
        :header-cell-style="{ background: '#eee', color: '#555' }">
        <el-table-column prop="Desc" label="交易类型"></el-table-column>
        <el-table-column prop="MerchantOrderNo" label="交易号"></el-table-column>
        <el-table-column prop="State" label="交易状态"></el-table-column>
        <el-table-column prop="RealityAmount" label="交易金额"></el-table-column>
        <el-table-column prop="TransactionTime" label="交易时间"></el-table-column>
      </el-table>
      <el-divider content-position="left"><i class="el-icon-notebook-1"></i>操作日志</el-divider>
      <div class="log" v-for="(item, index) in tag.detialData.Log" :key="index">
        {{ item.OperatorTime + '&nbsp;【' + item.Operator + '】&nbsp;' + item.Info }}
      </div>
      <div class="button" v-if="tag.isProcessOrder">
        <div>
          <el-button @click="draftSuccess" size="mini" type="success">出票完成</el-button>
          <el-button @click="dialogFormVisible = true" size="mini" type="warning">拒绝出票</el-button>
        </div>
      </div>
      <!-- 拒绝出票弹出框 -->
      <el-dialog :visible="dialogFormVisible">
        <el-form :model="subRefuseForm" :rules="rules" ref="refuseForm" label-width="120px">
          <el-form-item label="请输入拒绝理由" prop="refuseValue">
            <el-input v-model="subRefuseForm.refuseValue" type="textarea"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="quitRefuse">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { supplyDraftOperate } from '@/api/supply/draft'
const PnrReg = /^[A-Z0-9]{6}$/;
const ticketNoReg = /^(\d{3}[-]\d{10})$/g;
export default {
  name: 'supply-draft-detial',
  props: {
    tag: { type: Object }
  },
  data() {
    return {
      DraftData: {},
      activeNames: [],
      pnrDisable: false,
      ticketNoDisable: false,
      dialogFormVisible: false,
      subRefuseForm: {
        refuseValue: '',
      },
      rules: {
        refuseValue: [{ required: true, message: '请输入拒绝理由！', trigger: 'blur' }]
      }
    }
  },
  computed: {
    draftDetailShow() {
      return this.$store.state.supply.draftDetailShow
    },
    orderTags() {
      return this.$store.state.tags.orderTags;
    }
  },
  mounted() { this.DraftData = { ...this.tag.detialData } },
  methods: {
    toggle(row, column) {
      if (column.label == 'PNR') {
        row.isPnrSelected = true
      } else if (column.label == "票号") {
        row.isTicketNoSelected = true
      }
    },
    //检验PNR
    checkPnr(row) {
      if (PnrReg.test(row.PNR)) {
        supplyDraftOperate({ Type: "ModifyPnr", PrimaryKey: row.Id, Value: row.PNR }).then(res => {
          if (res.data.code == 0) {
            this.$message({ type: 'success', message: res.data.msg })
            row.isPnrSelected = false;
          }
        })
      } else {
        this.$message({ type: 'warning', message: '该值由6位大写字母或数字组成！' });
      }
    },
    //检验票号
    checkTicketNo(row) {
      if (ticketNoReg.test(row.TicketNo)) {
        supplyDraftOperate({ Type: "ModifyTicketNo", PrimaryKey: row.Id, Value: row.TicketNo }).then(res => {
          if (res.data.code == 0) {
            this.$message({ type: 'success', message: res.data.msg })
            row.isTicketNoSelected = false;
          }
        })
      } else {
        this.$message({ type: 'warning', message: '票号由前3位数字 - 后10为数字以及组成！' });
      }
    },
    //出票完成
    draftSuccess() {
      let params = {
        Type: "TicketingCompleted",
        PrimaryKey: this.tag.No,
        Value: ""
      }
      let p = this.tag.detialData.Passenger.every(item => item.PNR)
      let t = this.tag.detialData.Passenger.every(item => item.TicketNo)
      if (!p) return this.$message({ type: 'warning', message: 'PNR不能为空!' })
      if (!t) {
        this.tag.detialData.Passenger.forEach(item => {
          if (!item.TicketNo) {
            item.isTicketNoSelected = true
          }
        })
        return this.$message({ type: 'warning', message: '票号不能为空!' })
      }
      supplyDraftOperate(params).then(res => {
        if (res.data.code == 0) {
          this.$message({ type: 'success', message: res.data.msg });
          this.goBack()
        } else {
          this.$message({ type: 'warning', message: res.data.msg });
        }
      })
    },
    //拒绝出票
    quitRefuse() {
      let params = {
        Type: "TicketingRefusal",
        PrimaryKey: this.tag.No,
        Value: this.subRefuseForm.refuseValue
      }
      this.$refs['refuseForm'].validate((valid) => {
        if (valid) {
          supplyDraftOperate(params).then(res => {
            if (res.data.code == 0) {
              this.$message({ type: 'success', message: res.data.msg });
              this.dialogFormVisible = false
              this.goBack()
            } else {
              this.$message.error(res.data.msg);
            }
          })
        }
      })
    },
    //返回
    goBack() {
      this.$store.commit('showDraftTetailPage', false)
      this.$router.push({ path: '/supply/draft' })  //页面跳转到国内出票
      this.$store.commit('colseTag', this.tag)
    },
    handleChange(val) {
      this.activeNames = val
    },
  }
}
</script>

<style lang="less" scoped>
.supply-draft-detial {
  display: flex;
  flex-direction: column;
  width: 98%;
  height: 97%;
  padding: 10px;
  background: #fff;
  padding: 10px;

  .el-page-header {
    height: 24px;

    /deep/.el-page-header__content {
      font-size: 14px;
    }
  }

  .content {
    flex: 1;
    overflow-y: scroll;
  }

  .el-table {
    border-top: 1px solid #EBEEF5;
  }

  /deep/.el-divider__text {
    font-size: 16px;
    font-weight: 600;
    color: royalblue;
  }

  .el-row {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #909399;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .log {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .on-input {
    width: 100%;
    height: 100%;
  }

  /deep/.el-dialog {
    width: 40%;
  }

  .ticket-no {
    height: 23px;
  }

}
</style>