<template>
  <div class="layout">
    <!-- 左侧菜单 -->
    <left-nav class="left-nav"></left-nav>
    <!-- 右侧内容 -->
    <div class="content">
      <right-headers></right-headers>
      <!-- 路由出口 -->
      <div class="exit">
        <el-backtop target=".exit" :bottom="100">
          <i class="el-icon-top fw700"></i>
        </el-backtop>
        <keep-alive>
          <router-view 
            v-if="$route.meta.keepAlive && !draftDetailShow && !refundDetailShow && !rescheDetailShow && !trainDetailShow && !hotelDetailShow">
          </router-view>
        </keep-alive>
        <router-view 
          v-if="!$route.meta.keepAlive && !draftDetailShow && !refundDetailShow && !rescheDetailShow && !trainDetailShow && !hotelDetailShow">
        </router-view>
        <template v-for="item in orderTags">
          <draft-detail :key="item.id" :orderNo="item.No" :tag="item" v-if="item.type == 'draft' && item.isCurrent"></draft-detail>
          <refund-detail :key="item.id"  :tag="item" v-if="item.type == 'refund' && item.isCurrent"></refund-detail>
          <reschedul-detail :key="item.id"  :tag="item" v-if="item.type == 'reschedule' && item.isCurrent"></reschedul-detail>
          <train-order-detail :key="item.id"  :tag="item" v-if="item.type == 'train' && item.isCurrent"></train-order-detail>
          <hotel-order-detail :key="item.id"  :tag="item" v-if="item.type == 'hotel' && item.isCurrent"></hotel-order-detail>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import LeftNav from "./LeftNav.vue";
import RightHeaders from "./header/RightHeaders.vue";
import DraftDetail from '@/components/supply/DraftDetail.vue';  //国内出票详情
import RefundDetail from '@/components/supply/RefundDetail.vue';  //国内退票详情
import ReschedulDetail from '@/components/supply/ReschedulDetail.vue';  //国内改期详情
import TrainOrderDetail from '@/components/supply/TrainOrderDetail.vue';  //火车票处理
import HotelOrderDetail from '@/components/supply/HotelOrderDetail.vue';  //火车票处理

  export default {
    components: { 
      LeftNav,
      RightHeaders, 
      DraftDetail, 
      RefundDetail, 
      ReschedulDetail, 
      TrainOrderDetail, 
      HotelOrderDetail 
    },
    computed: {
      orderTags() {
        return this.$store.state.tags.orderTags
      },
      draftDetailShow() {
        return this.$store.state.supply.draftDetailShow
      },
      refundDetailShow() {
        return this.$store.state.supply.refundDetailShow
      },
      rescheDetailShow() {
        return this.$store.state.supply.rescheDetailShow
      },
      trainDetailShow() {
        return this.$store.state.supply.trainDetailShow
      },
      hotelDetailShow() {
        return this.$store.state.supply.hotelDetailShow
      }
    }
  }
</script>

<style lang="less" scoped>
  .layout {
    height: 100%;
    display: flex;
    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      .exit {
        flex: 1;
        position: relative;
        height: 100%;
        overflow-y: auto;
        padding: 20px;
        background-color: #f0f2f7;
      }
      ::-webkit-scrollbar {
        /*滚动条整体样式*/
        width : 5px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 5px;
      }
      ::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        box-shadow : inset 0 0 5px rgba(0, 0, 0, 0.2);
        background : #409eff;
      }
      ::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow : inset 0 0 5px rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        background : #fff;
      }
    }
  }
</style>