<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
}
</script>

<style>
.app {
  height: 100%;
}
</style>
