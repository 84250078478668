import request from '@/utils/request';

//查询供应订单-国内出票数据
export const querySupplyDraftList = (params) => {
  return request({
    url: `/SupplyOrder/QuerySupplyOrderList`,
    method: 'get',
    params
  })
}
//查询供应订单-国内出票详情数据
export const supplyDraftDetails = (data) => {
  return request({
    url: `/SupplyOrder/SupplyOrderDetails`,
    method: 'post',
    data,
    headers: {
			'Content-Type': "application/x-www-form-urlencoded"
		}
  })
}

/* 获取采购方数据 */
export const queryCompany = (data) => {
  return request({
    url: '/Basic/QueryFullCompany',
    method: 'post',
    data
  })
}

/* 出票完成 */
export const supplyDraftOperate = (data) => {
  return request({
    url: '/SupplyOrder/SupplyOrderOperate',
    method: 'post',
    data
  })
}

/* 线下退票 - 查询订单详情  */
export const offLineRefundOrder = (data) => {
  return request({
    url: '/SupplyRefundOrder/OfflineApplyRefundOrder',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/* 线下退票 - 提交退票 */
export const submitRefundOrder = (data) => {
  return request({
    url: '/SupplyRefundOrder/OfflineSubRefundOrder',
    method: 'post',
    data
  })
}

/* 线下改期 - 查询订单 */
export const offlineChangeOrder = (data) => {
  return request({
    url: "/SupplyChangeOrder/OfflineApplyChangeDetails",
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/* 线下改期 - 航班查询 */
export const offlineChangeFlight = (data) => {
  return request({
    url: "/SupplyChangeOrder/OfflineChangeOneWayFlight",
    method: 'post',
    data
  })
}

/* 线下改期 - 提交改期 */
export const submitChangeOrder = (data) => {
  return request({
    url: "/SupplyChangeOrder/OfflineSubmitChange",
    method: 'post',
    data
  })
}