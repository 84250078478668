/**
 * @date 2024.01.11
 * @file 推广分销模块接口文档
 */
import request from '@/utils/request';

/* 推广概览 start */
export function queryOverview(data) {
	return request({
		url: '/PromoteUser/QueryOverview',
		method: 'post',
		data
	})
}
//提现记录
export function withdrawCashDetails(params) {
	return request({
		url: '/PromoteUser/WithdrawalDetails',
		method: 'get',
		params
	})
}
/* end */

/* 推广注册 start */
// 获取图片验证码
export function getVerifyCode(data) {
	return request({
		url: '/PromoteUser/GetSmsVerifyCode',
		method: 'post',
		data
	})
}

// 发送短信验证码
export function sendMessageCode(data) {
	return request({
		url: '/PromoteUser/PromoteRegisterSms',
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	})
}
// 注册提交
export function submitRegister(data) {
	return request({
		url: '/PromoteUser/Register',
		method: 'post',
		data
	})
}

/* end */

/* 推广客户 start */
//查询客户
export function queryPromoteUser(params) {
	return request({
		url: '/PromoteUser/QueryPromoteUser',
		method: 'get',
		params
	})
}
//修改佣金
export function opreatePromoteUser(data) {
	return request({
		url: '/PromoteUser/OperatePromoteUser',
		method: 'post',
		data,
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	})
}
/* end */
/* 返佣明细 start */
//查询
export function queryRebateDel(params) {
	return request({
		url: '/PromoteUser/QueryRebateDetails',
		method: 'get',
		params
	})
}
/* end */
