//引入组件 
import AdminLayout from '@/views/admin-layout/Layout.vue';
import Layout from "../views/layout/Layout.vue";
import Home from "../views/home/Home.vue";
import Ticket from "../views/ticket/Ticket.vue";
import Travel from "../views/travel/Travel.vue";
import Open from "../views/open/Open.vue";
import Our from "../views/Forour/Our.vue";
import Login from "../views/login/Login.vue";
import Info from '../views/info/EntryInfo.vue';
import Register from '../views/promote-register/PromoteRegister.vue'

export const routes = [
  //重定向
  {
      path:'/',
      redirect: '/home' ,
  },{  //首页
      path:'/home',
      component: Layout,
      meta: { title: '首页' },
      children: [{ path: '', component: Home }]
  },{   //旅游产品
      path:'/travel',
      component: Layout,
      meta: { title: '旅游产品' },
      children: [{ path: '', component: Travel  }]
  },
   //机票产品
   {
      path:'/ticket',
      component: Layout,
      meta: { title: '机票产品' },
      children: [{ path: '', component: Ticket }]
  },
   //开放平台
   {
      path:'/open',
      component: Layout,
      meta: { title: '开放平台' },
      children: [{ path: '', component: Open }]
  },
  //关于我们
  {
      path:'/our',
      component: Layout,
      meta: { title: '关于我们' },
      children: [{ path: '', component: Our }]
  },
  {
    path: '/login',
    component: Layout,
    meta: { title: '登录' },
    children: [{ path: '', component: Login }]
  },
  {
    path: '/entry/info',
    component: Info,
    meta: {title: '信息录入'}
  },
  {
    path: '/promote/register',
    component: Layout,
    meta: {title: '推广注册'},
    children: [{ path: '/promote/register', component: Register }]
  },
   {
    path: '/404',
    component: () => import('@/views/admin-error/404.vue'),
    name: 'ERROR'
  }
 ]
 
export const staticRouter = [{
  path: '/password',
  component: AdminLayout,
  meta: {title: '修改密码', icon: 'fa fa-key'},
  hidden: true,
  children: [{
      path: '', 
      meta: {title: '修改密码', icon: 'fa fa-key'},
      component: () => import('@/views/admin-password/ModifyPassword.vue')
    }]
}, {
  path: "/notice",
  component: AdminLayout,
  hidden: false,
  name: "notice",
  meta: { title: "平台公告", icon: "fa fa-volume-up", roles: null },
  children: [
    {
      path: "/notice/plat",
      component:  () => import('@/views/admin-notice/NoticePlat.vue'),
      name: "notice",
      meta: { title: "平台公告", icon: "fa fa-volume-down", roles: null }
    }
  ],
}, {
  path: '/hotel/payment',
  component: () => import('@/views/payment/HotelPayment.vue'),
  name: 'hotelPayment',
  meta: { title: '酒店订单支付' }
}, {
  path: '/train/payment',
  component: () => import('@/views/payment/TrainPayment.vue'),
  name: 'trainPayment',
  meta: { title: '车票订单支付' }
}, {
  path: '/flight/payment',
  component: () => import('@/views/payment/FlightPayment.vue'),
  name: 'flightPayment',
  meta: { title: '机票订单支付' }
}]
export const whiteList = ['/', '/home', '/travel', '/ticket', '/open', '/our', '/entry/info', '/promote/register', '/404'];


