<template>
  <div class="menu-tags">
    <el-tag
      v-for="tag in routerTags"
      :key="tag.path"
      size="small"
      :closable="isAffix(tag) ? false : true"
      @click="routerTagClick(tag)"
      @close="handleClose(tag)">
      <router-link 
        class="tags-item" 
        :to="tag.path"
        @contextmenu.prevent.native="openMenu(tag,$event)">
        {{ tag.title }}
      </router-link>
    </el-tag>
    <el-tag 
      class="order-tag"
      :class="item.isCurrent ? 'active': ''"
      v-for="item in orderTags"
      :key="item.id"
      size="small"
      closable 
      @click="orderTagClick(item)" 
      @close="OrderTagClose(item)"
      @contextmenu.prevent.native="openMenu(item,$event)">
      {{item.title}}
    </el-tag>
    <ul class="menu-context" v-show="visible" :style="{top: top + 'px', left: left + 'px'}">
      <li v-if="!isAffix(selectedTag)" @click="closeSelectedTag(selectedTag)">关闭当前</li>
      <li @click="closeOthersTags(selectedTag)">关闭其他</li>
      <li @click="closeAllTags(selectedTag)">关闭全部</li>
    </ul>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        visible: false, //标签右键卡片是否显示
        top: 0,  //标签右键卡片位置
        left: 0,
        selectedTag: {},
        isCurrent: false
      }
    },
    created() {
      this.initTags()
    },
    computed: {
      routerTags() {
        return this.$store.state.tags.routerTags;
      },
      orderTags() {
        return this.$store.state.tags.orderTags;
      },
    }, 
    methods: {
      isAffix(tag) {
        return tag.affix
      },
      //计算标签
      initTags() {
        this.visible = false
        this.$store.commit('addRouterTag', this.$route)
      },
      //关闭标签页
      handleClose(tag) {
        this.visible = false; //关闭标签右键卡片
        this.$store.commit('colseTag', tag)
      },
      routerTagClick(tag) {
        this.$store.commit('changeOrderTag'); //订单详情失活
        this.$store.commit('showDraftTetailPage', false);//关闭出票详情
        this.$store.commit('showRefundDetailPage', false);//关闭退票详情
        this.$store.commit('showRescheDetailPage', false) //关闭改期详情页
        this.$store.commit('showTrainDetailPage', false) //关闭火车票详情页
        this.$store.commit('showHotelDetailPage', false) //关闭酒店订单详情页
        const element = document.querySelectorAll('.tags-item');
        element.forEach(item => {
          if(item.text == tag.title) {
            item.classList.add('router-link-active');  //添加激活样式
          }
        })
        this.$router.push({path: tag.path})
      },
      // 打开右键卡片
      openMenu(tag, e) {
        this.left = e.clientX - 25
        this.top = e.clientY + 15
        this.visible = true
        this.selectedTag = tag
      },
      //关闭当前标签
      closeSelectedTag(tag) {
        this.$store.commit('showDraftTetailPage', false);//关闭出票详情
        this.$store.commit('showRefundDetailPage', false);//关闭退票详情
        this.$store.commit('showRescheDetailPage', false) //关闭改期详情页
        this.$store.commit('showTrainDetailPage', false) //关闭火车票详情页
        this.$store.commit('showHotelDetailPage', false) //关闭酒店订单详情页
        this.handleClose(tag)
      },
      //关闭其他标签
      closeOthersTags(tag) {
        this.visible = false;
        this.$router.push(tag.path)
        this.$store.commit('colseOtherTag', tag)
      },
      //关闭所有标签
      closeAllTags() {
        this.visible = false
        this.$store.commit('showDraftTetailPage', false);//关闭详情
        this.$store.commit('showRefundDetailPage', false);//关闭详情
        this.$store.commit('showRescheDetailPage', false) //关闭详情页
        this.$store.commit('showTrainDetailPage', false) //关闭火车票详情页
        this.$store.commit('showHotelDetailPage', false) //关闭酒店订单详情页
        //恢复初始状态
        this.$store.commit('closeAllTag')
      },
      //点击订单标签
      orderTagClick(tag) {
        if(tag.type == 'draft' && tag.isCurrent) {
          this.$store.commit('showDraftTetailPage', true);//显示详情
        }else if(tag.type == 'refund' && tag.isCurrent) {
          this.$store.commit('showRefundDetailPage', true);//显示详情
        } else if(tag.type == 'reschedule' && tag.isCurrent) {
          this.$store.commit('showRescheDetailPage', true) //显示详情页
        } else if(tag.type == 'train' && tag.isCurrent) {
          this.$store.commit('showTrainDetailPage', true) //显示详情页
        } else if(tag.type == 'hotel' && tag.isCurrent) {
          this.$store.commit('showHotelDetailPage', true) //显示详情页
        }
        this.$store.commit('changeOrderTag', tag);  //切换tag
        //使路由标签失活
        const element = document.querySelectorAll('.tags-item');
        element.forEach(item => {
          item.classList.remove('router-link-active');  //移除激活样式
        })
      },
      OrderTagClose(tag) {
        this.$store.commit('showDraftTetailPage', false);//关闭详情
        this.$store.commit('showRefundDetailPage', false);//关闭详情
        this.$store.commit('showRescheDetailPage', false) //关闭改期详情页
        this.$store.commit('showTrainDetailPage', false) //关闭火车票详情页
        this.$store.commit('showHotelDetailPage', false) //关闭酒店订单详情页
        this.handleClose(tag)
      }
    },
    //侦听器
    watch: {
      //监听路由变化 ，获取路由标签
      "$route.path"() {
        this.initTags();
        this.$store.commit('showDraftTetailPage', false);//关闭详情
        this.$store.commit('showRefundDetailPage', false);//关闭详情
        this.$store.commit('showRescheDetailPage', false) //关闭改期详情页
        this.$store.commit('showTrainDetailPage', false) //关闭火车票详情页
        this.$store.commit('showHotelDetailPage', false) //关闭酒店订单详情页
      },
      "orderTags.length"(newValue, oldValue) {
        if(newValue > oldValue && newValue > 0) {
          const element = document.querySelectorAll('.tags-item');
          element.forEach(item => {
            item.classList.remove('router-link-active');  //移除激活样式
          })
        } else {
          const element = document.querySelectorAll('.tags-item');
          element[element.length -1].classList.add('router-link-active');  //移除激活样式
        }
      }
    }

  }
</script>

<style lang="less" scoped>
  .el-tag {
    margin-left: 10px;
    margin-top: 5px;
  }
  a {
    color: #000 !important;
    text-decoration: none;
  }
  .router-link-active {
    color: #409eff !important;
    text-decoration: none;
    position: relative;
  }
  .tags-item {
    position: relative;
  }
  .menu-context {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
  .menu-tags {
    .order-tag {
      color: #333;
      &:hover {
        cursor: pointer;
      }
    }
    .active {
      color: #409eff !important;
    }
  }
</style>