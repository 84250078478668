<template>
  <div class="forget-page">
    <el-steps :active="active" finish-status="success">
      <el-step title="填写登录名"></el-step>
      <el-step title="验证"></el-step>
      <el-step title="重置密码"></el-step>
    </el-steps>
    <el-form v-if="firstStep" :model="form" ref="Form" :rules="rule" label-width="96px" size="mini">
      <div class="img-code">
        <img :src="firstCode">
      </div>
      <el-form-item prop="code" label="请输入验证码">
        <el-input v-model="form.code"></el-input>
      </el-form-item>
      <el-form-item prop="phone" label="请输入手机号">
        <el-input v-model="form.phone" @blur="phoneBlur"></el-input>
      </el-form-item>
      <el-form-item prop="pCode" class="code-box">
        <el-input class="code" v-model="form.pCode"></el-input>
        <el-button size="mini" @click="getPhoneCode" type="primary" plain>{{showText ? '获取验证码' : time + 's重新获取'}}</el-button>
      </el-form-item>
      <el-button @click="nextStep" size="small">下一步</el-button>
      <el-button @click="cancel" size="small">取消</el-button>
    </el-form>
    <el-form :model="passForm" label-width="90px" ref="passForm" :rules="passRule" size="mini" v-else>
      <el-form-item prop="password" label="重置密码">
        <el-input v-model="passForm.password"></el-input>
      </el-form-item>
      <el-form-item prop="comfirmPass" label="确认密码">
        <el-input v-model="passForm.comfirmPass"></el-input>
      </el-form-item>
      <el-button @click="lastStep" size="small">上一步</el-button>
      <el-button type="primary" size="small" @click="submit">提交</el-button>
    </el-form>
  </div>
</template>

<script>
import { getForgetCode, forgotPasswordSms, forgetPassword } from '@/api/login'
import {checkTel} from '@/utils/validator'
  export default {
    data() {
      var validateCode = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('输入验证码'));
        } else {
          callback();
        }
      };
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.passForm.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        active: 0,
        identifying: '',
        firstCode: '',
        form: {
          code: '',
          phone: '',
          pCode: ''
        },
        rule: {
          code: [{ validator: validateCode, trigger: 'blur'}],
          phone: [{ validator: checkTel, trigger: 'blur'}],
          pCode: [{ validator: validateCode, trigger: 'blur'}],
        },
        showText: true,
        time: 59,
        timer: null,
        error: '',
        firstStep: true,
        passForm: {
          password: '',
          comfirmPass: ''
        },
        passRule: {
          password: [{validator: validatePass, trigger: 'blur'}],
          comfirmPass: [{validator: validatePass2, trigger: 'blur'}],
        }
      }
    },
    created() {
      this.getImgCode()
    },
    methods: {
      getImgCode() {
        getForgetCode().then(res => {
          if(res.data.code == 0) {
            let {identifying, img} = res.data.data;
            this.identifying = identifying;
            this.firstCode = decodeURIComponent(img)
          }
        })
      },
      //获取手机短信验证码
      getPhoneCode() {
        if(!this.form.phone) {
          return this.$refs.Form.validateField('phone')
        }
        this.showText = false
        this.timer = setInterval(() => {
          this.time--
          if(this.time < 0) {
            this.showText = true
            clearInterval(this.timer)
          }
        }, 1000)
        let params = {
          identifying: this.identifying,
          code: this.form.code,
          phone: this.form.phone
        }
        forgotPasswordSms(params).then(res => {
          let {code, msg} = res.data;
          if(code == 0) {
            this.$message({type: 'success', message: msg})
          } else {
            this.getImgCode()
            clearInterval(this.timer);
            this.showText = true
            this.$message({type: 'error', message: msg})
          }
        })
      },
      phoneBlur() {
        if(this.form.phone) {
          this.active = 1
        } else {
          this.active = 0
        }
      },
      //下一步
      nextStep() {
        this.$refs['Form'].validate(valid => {
          if(valid) {
            this.firstStep = false
            this.active = 2
          }
        })
      },
      //上一步
      lastStep() {
        this.firstStep = true
        this.active = 1
      },
      //提交
      submit() {
        this.$refs['passForm'].validate(valid => {
          if(valid) {
            let p = {
              password: this.passForm.password,
              code: this.form.pCode,
              phone: this.form.phone
            }
            forgetPassword(p).then(res => {
              let {code, msg} = res.data;
              if(code == 0) {
                this.$message({type: 'success', message: msg})
                this.$parent.isShow = false;
                this.$refs.passForm.resetFields();
              } else {
                this.$message({type: 'error', message: msg})
                this.lastStep()
              }
            })
          }
        })
      },
      //取消
      cancel() {
        this.$parent.isShow = false;
        this.$refs.Form.resetFields();
      }
    }
  }
</script>

<style lang="less" scoped>
.forget-page {
  position: fixed;
  top: 0;
  left: 0;
  padding: 100px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  .el-steps--horizontal {
    white-space: nowrap;
    width: 40%;
  }
  /deep/.el-step__head.is-process {
    color: #409ef6;
    border-color: #409ef6;
  }
  /deep/.el-step__title.is-process {
    color: #409ef6;
  }
  .img-code {
    width: 300px;
    margin-top: 20px;
  }
  .el-form {
    margin-top: 20px;
    .el-input {
      width: 180px;

    }
  }
  .code-box {
    /deep/.el-form-item__content {
      margin-left: 0 !important;
      display: flex;
      .code {
        margin-right: 5px;
      }
    }
  }
  .pass-input {
    display: flex;
    align-items: center;
    margin: 10px 0;
    span {
      width: 70px;
      font-size: 14px;
    }
    .el-input {
      width: 180px;
    }
  }
}
</style>