<template>
	<div class="spring-from">
		<!-- from -->
		<div class="from">
			<div class="from-title">登录</div>
			<div>
				<el-form :model="ruleForm" ref="ruleForm" :rules="rules" class="demo-ruleForm">
					<el-form-item prop="name">
						<el-input v-model="ruleForm.name" placeholder="账号"></el-input>
					</el-form-item>
					<el-form-item prop="pass">
						<el-input type="password" v-model="ruleForm.pass" show-password placeholder="密码"></el-input>
					</el-form-item>
					<div class="form_btn">
						<!-- <el-button type="danger" @click="submitForm">登录</el-button> -->
						<el-button id="captcha-button" type="danger">登录</el-button>
						<el-button type="primary" @click="register">注册</el-button>
					</div>
				</el-form>
			</div>
      <div class="forget-passeord">
        <el-button type="text" @click="isShow = true">忘记密码</el-button>
      </div>
		</div>
    <forget v-if="isShow"></forget>
    <div id="captcha-element"></div>
	</div>
</template>

<script>
import { login } from '@/api/login'; //接口请求方法
import local from '@/utils/local';  //引入本地存储
import MD5 from '@/utils/md5.js'  //引入密码加密
import { encryptRsa } from '@/utils/jsencrypt';  //RSA加密
import Forget from '@/components/Forget.vue';
import { checkAccount, validatePass } from '@/utils/validator.js'

export default {
  components: {Forget},
	data() {
		
		return {
			//上传文件
			dialogImageUrl: '',
			dialogVisible: false,
			disabled: false,
      isValidate: false,
      ruleForm: {
        name: '',
        pass: ''
      },
      rules: {
        name: [
          { validator: checkAccount, trigger: 'blur' }
        ],
        pass: [
          { validator: validatePass, trigger: 'blur' }
        ]
      },
      isShow: false,
      captcha: null,
      captchaButton: null,
		}
	},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      //默认账号
      let user = local.get('userInfo');
      this.ruleForm.name = user ? user.Account : '';

      //初始化验证码
      this.captchaButton = document.getElementById('captcha-button');
      window.initAliyunCaptcha({
        SceneId: '1p13qq5w',
        prefix: 'rr9e3o',
        mode: 'popup', // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
        element: '#captcha-element', // 页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
        button: '#captcha-button', // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
        captchaVerifyCallback: this.captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
        onBizResultCallback: this.onBizResultCallback, // 业务请求结果回调函数，无需修改
        getInstance: this.getInstance, // 绑定验证码实例函数，无需修改
        slideStyle: {
          width: 360,
          height: 40,
        }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
        language: 'cn', // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
      });
    },
    //注册
    register() {},
    getInstance(instance) {
      this.captcha = instance;
    },
    async captchaVerifyCallback(captchaVerifyParam) {
      this.$refs['ruleForm'].validate((valid) => {
        if(valid) {
          this.isValidate = true
        }
      })
      if(!this.isValidate) {
        this.init()
        return
      }
      //登录数据
      let loginData = {
        account: this.ruleForm.name,
        password: MD5.hexMD5(this.ruleForm.pass),
        type: 2,
        identifying: '',
        code: captchaVerifyParam
      }
      let str = encryptRsa(loginData)
      let res = await login({ str, _: Date.now() })
      let {code, data, msg} = res.data
      if (code == 0) {
        this.$message({ message: msg, type: 'success' });
        local.set('userInfo', data)
        local.set('tk', data.Token)
      } else {
        this.init()
        this.$message({ message: msg, type: 'warning' });
        this.ruleForm.pass = '';
      }
      return {
        captchaResult: code == 0, //验证码验证是否通过
        bizResult: code == 0, //业务验证是否通过
      }
    },
    // 验证通过后调用
    onBizResultCallback(value) {
      if(value) {
        this.$router.push({path: '/notice/plat'});  //跳转到后台机票预订页
      }
    },
  },
  beforeDestroy() {
    this.captchaButton = null;

    // 必须删除相关元素，否则再次mount多次调用 initAliyunCaptcha 会导致多次回调 captchaVerifyCallback
    document.getElementById('aliyunCaptcha-mask')?.remove();
    document.getElementById('aliyunCaptcha-window-popup')?.remove();
  }
}
</script>

<style lang="less" scoped>
.spring-from {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/imgs/飞机.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .from {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: -7px -7px 12px rgba(70, 69, 69, 0.4);
    padding: 50px 60px 10px;
    margin: 124px;
    text-align: center;
    border-radius: 4px;
    width: 400px;
    height: 360px;
    background-color: rgba(10, 10, 10, 0.46);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .from-title {
      margin-bottom: 22px;
      color: aliceblue;
      font-weight: bolder;
      font-size: 20px;
    }
    .form_btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }
  .forget-passeord {
    display: flex;
    justify-content:left;
  }
}
</style>