<template>
    <!-- 首图 -->
    <div v-if="show" class="ticket">
        <!-- 首图 -->
        <div class="carousel">
            <div style="height:800px">
                <img style="height:100%;width:100%" :src="data.headerimg" alt="">
            </div>
        </div>
        <!-- 图一 -->
        <OfficialTitle :data="data.model2"></OfficialTitle>
        <div class="img1">
            <el-row :gutter="40">
                <el-col v-for="(item, index) in data.model1" :key="index" :xs="12" :sm="8" :md="6" :lg="6" :xl="6">
                    <div class="img1-box">
                        <div class="title">{{ item.content }}</div>
                        <div class="text">{{ item.title }}</div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 标题 -->
        <OfficialTitle :data="data.model2"></OfficialTitle>
        <!-- 图二 -->
        <div class="img2-bg">
            <el-row :gutter="20">
                <el-col style="height:400px" v-for="(item, index) in data.model2.Cardmodels" :key="index" :xs="24" :sm="12"
                    :md="8" :lg="8" :xl="8">
                    <div class="img2-bgcontent">
                        <div class="img">
                            <img style="height:100%;width:100%" :src="item.img" alt="">
                        </div>
                        <div class="title"> {{ item.title }} </div>
                        <div class="Content">{{ item.content }}</div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 标题 -->
        <OfficialTitle :data="data.model3.modelCardmodels1"></OfficialTitle>
        <!-- 图三 -->
        <div class="img2-bg">
            <el-row :gutter="20">
                <el-col style="height:400px" v-for="(item, index) in data.model3.modelCardmodels1.Cardmodels" :key="index"
                    :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                    <div class="img2-bgcontent">
                        <div class="img">
                            <img style="height:100%;width:100%" :src="item.img" alt="">
                        </div>
                        <div class="title"> {{ item.title }} </div>
                        <div class="Content">{{ item.content }}</div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 标题 -->
        <OfficialTitle :data="data.model3.modelCardmodels2"></OfficialTitle>
        <!-- 图四 -->
        <div class="img2-bg">
            <el-row :gutter="20">
                <el-col style="height:400px" v-for="(item, index) in data.model3.modelCardmodels2.Cardmodels" :key="index"
                    :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
                    <div class="img2-bgcontent">
                        <div class="img">
                            <img style="height:100%;width:100%" :src="item.img" alt="">
                        </div>
                        <div class="title"> {{ item.title }} </div>
                        <div class="Content">{{ item.content }}</div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import { jsonData } from '@/api/jsonData/jsondata'
import OfficialTitle from '@/components/official/OfficialTitle.vue'
export default {
    components: { OfficialTitle },
    data() {
        return {
            data: {},
            show: false
        }
    },
    created() {
        jsonData().then(res => {
            this.data = JSON.parse(res.data.data).ticketjson
            this.show = true
        })
    },
    methods: {
    }
}
</script>

<style lang="less" scoped>
.el-col {
    margin: 30px 0 !important;
}
.carousel {
    margin: 80px 0;
}
.img1 {
    margin: 40px auto;
    width: 70%;
    .img1-box {
        padding: 80px 0;
        text-align: center;
        border: 10px;
        background-color: #f7f7f7;
        color: #999999;
        .title {
            font-family: "宋体";
            font-size: 22px;
        }
        .text {
            margin-top: 10px;
            text-transform: uppercase;
            font-size: 12px;
        }
    }
    .img1-box:hover {
        color: #FFF;
        background: coral;
    }
}
.img2-bg {
    background: #f0efef;
    padding: 100px 300px;

    .img2-bgcontent {
        height: 100%;
        background: #fff;
        padding: 10px;

        .img {
            height: 70%;
            img {
                display: block;
                height: 100%;
                margin: 0 auto;
                -webkit-transition-duration: .5s;
                -moz-transition-duration: .5s;
                transition-duration: .5s
            }
        }
        .img:hover img {
    transform: scale(1.1)
}
        .title {
            font-size: 16px;
            font-weight: bold;
            color: #000;
            padding: 20px 0;
            border-bottom: 1px solid #999;
        }

        .Content {
            font-size: 16px;
            margin-top:10px ;
            line-height: 20px;
            color: #373737;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
    .img2-bgcontent:hover {
        margin-top: -6px
    }.img2-bgcontent:hover .title{
            color: #b21f1f;
            border-bottom: 1px solid #b21f1f;
        }
}
</style>