<template>
  <div class="header-nav">
    <div class="nav">
      <div class="logo">
        <img class="logo-img" src="../../assets/imgs/icon.png" alt="">
        <img class="logo-text" src="../../assets/imgs/logo-text.png" alt="">
      </div>
      <el-menu close="mode" mode="horizontal"  :default-active="$route.path" router  active-text-color="#e75555">
        <el-menu-item index="/home">首页</el-menu-item>
        <el-menu-item index="/travel">旅游产品</el-menu-item>
        <el-menu-item index="/ticket">机票产品</el-menu-item>
        <el-menu-item index="/open">开放平台</el-menu-item>
        <el-menu-item index="/our">关于我们</el-menu-item>
      </el-menu>
      <div class="toLogin" @click="toLogin">进入后台</div>
    </div>
  </div>
</template>

<script>
import { checkLogin } from '@/api/login'
import local from '@/utils/local'
export default {
  data() {
    return {
      icon: require("../../assets/imgs/logo.png"),
    }
  },
  methods: {
    toLogin() {
      let token = local.get('tk')
      if(token) {
        checkLogin().then((res) => {
          if (res.data.code == 0) {
            this.$router.push({path: '/notice/plat'})
          }else {
            this.$message({type: 'warning', message: '非法请求，请重新登录！'})
            this.$router.push({path: '/login'})
          }
        })
      }else {
        this.$router.push('/login')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.header-nav {
  width: 100%;
  height: 60px;
  background-image: url(../../assets/imgs/背景1.jpg);
  background-size:100% 100%;
  background-repeat: no-repeat;
  
  .nav {
    padding: 0 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bolder;
    .logo {
      display: flex;
      align-items: center;
      .logo-img {
        width: 50px;
        height: 50px;
      }
      .logo-text {
        width: 150px;
      }
    }
    .toLogin {
      box-sizing: border-box;
      width: 104px;
      height: 60px;
      line-height: 60px;
      cursor: pointer;
    }
  }
  .el-menu-item {
      font-size:16px;
      color:#000;
  }
  .el-menu--horizontal{
      border-bottom: none;
      background: none;
  }
}
</style>