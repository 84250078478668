<template>
  <div class="right-header">
    <div class="right-header-top">
      <div class="bread">
        <i
          :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
          @click="changeCollapse"></i>
        <!-- 面包屑 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item v-for="bread in breads" :key="bread.to" :to="bread.to">
            {{bread.title}}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <el-dropdown @command="handleCommand" trigger="click" class="adminmaster">
        <span class="el-dropdown-link">
          <el-avatar shape="circle" size="small" icon="el-icon-user-solid"></el-avatar>
          {{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item  command="modify">修改密码</el-dropdown-item>
          <el-dropdown-item  command="clearCache">清空缓存</el-dropdown-item>
          <el-dropdown-item  command="logout">注销登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <menu-tags></menu-tags> 
  </div>
</template>

<script>
  import MenuTags from "./MenuTags.vue"
  import local from '@/utils/local';
  import  { clearCache } from '@/api/login'
  import { logout } from "@/api/user";
  export default {
    data() {
      return {
        isCollapse: false, //是否折叠导航
        breads: [], //面包屑
        userName: ''
      }
    },
    created() {
      this.clacBreads()
      this.userName = local.get('userInfo').Name
    },
    components: { MenuTags },
    methods: {
      //计算面包屑
      clacBreads() {
        const r = this.$route.matched
          .filter((v) => v.meta.title)
          .map((v) => {
            return { to: v.path, title: v.meta.title };
          });
        this.breads = [...r];
      },

      //改变左侧导航折叠
      changeCollapse() {
        this.isCollapse = !this.isCollapse;
        //把值传给左侧导航
        this.$bus.$emit("changeCollapse", this.isCollapse);
      },
      //点击头像，下拉菜单
      handleCommand(command) {
        if(command == 'modify') {
          this.$router.push('/password')
        } else if(command == 'clearCache') {
          clearCache().then(res => {
            if(res.data.code == 0) {
              this.$message({type: 'success', message: res.data.msg})
            }
          })
        }else if(command == 'logout') {
          logout().then(res => {
            if(res.data.code == 0) {
              local.clear()
              this.$router.push('/home');  //跳转到登录
            } else {
              this.$message({type: 'error', message: res.data.msg})
            }
          })
        }
      }
    },
    //侦听器
    watch: {
      //监听地址栏变化（地址栏变化，说明导航变化）
      "$route.path"() {
        this.clacBreads();
      },
    },
  }
</script>

<style lang="less" scoped>
  .right-header {
    height: 10%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .right-header-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      // border-bottom: 1px solid #eee;
      box-shadow: 0 3px 5px -6px rgb(119, 118, 118);
      .bread {
        display: flex;
        align-items: center;
        margin-left: 10px;
        .el-breadcrumb {
          margin-left: 20px;
        }
      }
      .adminmaster {
        margin-right: 10px;
        .el-dropdown-link {
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #409EFF;
          .el-avatar {
            background: #409EFF;
            margin-right: 5px;
          }
        }
        .el-icon-arrow-down {
          font-size: 16px;
        }
       }
    }
  }
</style> 